import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { ContextService } from "../../application/shared/facade/context.service";
import { FeaturetoggleService } from "../api/feature-toggle/featuretoggle.service";

@Injectable({
  providedIn: "root",
})
export class DefaultRedirectGuardService implements CanActivate {
  constructor(
    private readonly route: Router,
    private readonly featuretoggleService: FeaturetoggleService,
    private readonly contextService: ContextService
  ) {}

  canActivate() {
    if (
      (this.contextService.userContext.hasNscRole ||
        this.contextService.userContext.hasNscAdminRole) &&
      this.featuretoggleService.isActive("OverviewModule")
    ) {
      return this.route.navigateByUrl("/overview");
    } else {
      return this.route.navigateByUrl("/leadtimes");
    }
  }
}
