import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Partner } from "../../models/models.api";
import { APISimpleService } from "../generic/api-simple.service";
import { PartnerServiceConfigService } from "./partner-service-config.service";


@Injectable({
  providedIn: "root",
})
export class PartnerService extends APISimpleService<Partner> {
  constructor(http: HttpClient, config: PartnerServiceConfigService) {
    super(http, config);
  }
}
