import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { map } from "rxjs";
import { ContextService } from "../../application/shared/facade/context.service";

@Injectable({
  providedIn: "root",
})
export class SalesTypesMarketEnabledGuard implements CanActivate {
  constructor(
    private readonly contextService: ContextService,
    private readonly route: Router
  ) {}

  canActivate() {
    return this.contextService.currentMarket$().pipe(
      map((market) => market.usePlannedProduction ?? false),
      map((usePlannedProduction) =>
        usePlannedProduction ? true : this.route.parseUrl("/")
      )
    );
  }
}
