import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FeaturetoggleService } from '../api/feature-toggle/featuretoggle.service';

@Injectable({
  providedIn: 'root'
})



export class NgxFeatureToggleGuardForSlotsService implements CanActivate {
  constructor(
    private readonly featuretoggleService : FeaturetoggleService,
    public router: Router ) {
  }
  canActivate() {
    return this.featuretoggleService.isActive('Slots') || this.router.navigate([''])
  }

}

