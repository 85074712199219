import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { merge, noop, Observable, of, ReplaySubject, Subject } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap
} from "rxjs/operators";
import {
  APIBodyServiceConfig, APIServiceConfig, APIServiceState, ApiServiceStatus
} from "../../../models/models.api.generic";

@Injectable()
export abstract class ApiService<RC, RS> implements OnDestroy {
  private readonly initialState: APIServiceState<RC, RS> = {
    request: null,
    response: null,
    state: ApiServiceStatus.NOT_TRIGGERED,
  };

  constructor(
    protected readonly http: HttpClient,
    protected readonly config: APIServiceConfig<RC>,
    protected readonly body?: APIBodyServiceConfig<RC>
  ) {
  }

  public execute(data: RC) {
    this.data$.next(data);
  }

  // === STATE OBSERVABLES
  private readonly store$ = new ReplaySubject<Partial<APIServiceState<RC, RS>>>(
    1
  );
  public readonly state$ = this.store$.asObservable().pipe(
    scan((acc: APIServiceState<RC, RS>, newVal: APIServiceState<RC, RS>) => {
      return { ...acc, ...newVal };
    }),
    startWith(this.initialState),
    shareReplay(1)
  );

  // == INTERACTION OBSERVABLESS
  private readonly data$ = new Subject<RC>();

  // == INTERMEDIATE OBSERVABLES
  private readonly trigger$ = this.data$.pipe(
    tap((request) =>
      this.store$.next({
        request,
        state: ApiServiceStatus.LOADING,
      })
    ),
    switchMap((request) =>
      this.config.url$(request).pipe(
        map((url) => {
          return { request, url };
        })
      )
    ),
    switchMap((request) =>
      this.body?.body$(request.request)?.pipe(
        map((body) => {
          return { url: request.url, body };
        })
      ) ?? of({url: request.url, body: null})
    ),
    mergeMap((value) =>
      this.httpRequest$(value.url, value.body).pipe(
        catchError((err) => {
          console.error(err);
          return of(new HttpErrorResponse(err));
        })
      )
    ),
    shareReplay(1)
  );

  protected abstract httpRequest$(url: string, body?: any): Observable<RS>;

  private readonly responseTrigger$ = this.trigger$.pipe(
    tap((response) => {
      if (response instanceof HttpErrorResponse) {
        this.store$.next({ state: ApiServiceStatus.ON_FAILURE });
        this.store$.next({ state: ApiServiceStatus.ERROR, response });
      } else {
        this.store$.next({ state: ApiServiceStatus.ON_SUCCESS });
        this.store$.next({ state: ApiServiceStatus.SUCCEEDED, response });
      }
    })
  );

  // == OUTPUT OBSERVABLES
  public requestContext$(predicate: (value: RC) => void = noop) {
    return this.state$.pipe(
      filter((vm) => !!vm.request),
      map((vm) => vm.request),
      distinctUntilChanged(),
      tap((value) => (value ? predicate(value) : noop))
    );
  }

  public response$(predicate: (value: RS | HttpErrorResponse) => void = noop) {
    return this.state$.pipe(
      filter((vm) => !!vm.response),
      map((vm) => vm.response),
      distinctUntilChanged(),
      tap((value) => (value ? predicate(value) : noop))
    );
  }

  public dataResponse$(predicate: (value: RS) => void = noop) {
    return this.state$.pipe(
      filter((vm) => !!vm.response),
      filter((vm) => !(vm.response instanceof HttpErrorResponse)),
      map((vm) => vm.response as RS),
      distinctUntilChanged(),
      tap((value) => (value ? predicate(value) : noop))
    );
  }

  public errorResponse$(predicate: (value: HttpErrorResponse) => void = noop) {
    return this.state$.pipe(
      filter((vm) => !!vm.response),
      filter((vm) => vm.response instanceof HttpErrorResponse),
      map((vm) => vm.response as HttpErrorResponse),
      distinctUntilChanged(),
      tap((value) => (value ? predicate(value) : noop))
    );
  }

  public on_success$(predicate: () => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state === ApiServiceStatus.ON_SUCCESS),
      distinctUntilChanged(),
      tap((onSuccess) => (onSuccess ? predicate() : noop)),
      filter((onSuccess) => onSuccess)
    );
  }

  public succeeded$(predicate: () => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state === ApiServiceStatus.SUCCEEDED),
      distinctUntilChanged(),
      tap((succeeded) => (succeeded ? predicate() : noop)),
      filter((succeeded) => succeeded)
    );
  }

  public on_failure$(predicate: () => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state === ApiServiceStatus.ON_FAILURE),
      distinctUntilChanged(),
      tap((onFailure) => (onFailure ? predicate() : noop)),
      filter((onFailure) => onFailure)
    );
  }

  public error$(predicate: () => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state === ApiServiceStatus.ERROR),
      distinctUntilChanged(),
      tap((error) => (error ? predicate() : noop)),
      filter((error) => error)
    );
  }

  public loading$(predicate: () => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state === ApiServiceStatus.LOADING),
      distinctUntilChanged(),
      tap((loading) => (loading ? predicate() : noop)),
      filter((loading) => loading)
    );
  }

  public status$(predicate: (status: ApiServiceStatus) => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state),
      distinctUntilChanged(),
      tap((simpleServiceStatus) => predicate(simpleServiceStatus))
    );
  }

  // == SUBSCRIPTIONS ==
  private readonly subscriptions = merge(this.responseTrigger$).subscribe();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
