import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MessageType } from "./model";
import { SnackbarmessageComponent } from "./snackbarmessage/snackbarmessage.component";

@Injectable({
  providedIn: "root",
})

export class MessageService {
  constructor(private readonly matSnackBar: MatSnackBar) {}

  public showSnackBar(
    snackbarMessage: string,
    dismissMessage = "Dismiss",
    messageType: MessageType = MessageType.error,
    displayVerticalPosition: MatSnackBarVerticalPosition = "bottom",
    displayDuration = 3000
  ) {
    return this.matSnackBar.openFromComponent(SnackbarmessageComponent, {
      data: {
        message: snackbarMessage,
        dismiss: dismissMessage,
        messageType,
      },
      horizontalPosition: "center",
      verticalPosition: displayVerticalPosition,
      panelClass: messageType === MessageType.error ? "vcc-snackBar-error" : "vcc-snackBar-info",
      duration: displayDuration,
    });
  }
}
