import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ContextService } from "../../../application/shared/facade/context.service";
import { FilterContainer } from "../../../ui/menus/search-bar/shared/models/models";
import { SimpleServiceConfig } from "../../models/models.api.generic";
import { APISimpleService } from "../generic/api-simple.service";

@Injectable({
  providedIn: "root",
})
export class VisibilityFilterServiceConfig extends SimpleServiceConfig<FilterContainer> {
  constructor(
    private readonly contextService: ContextService
  ) {
    super();
  }

  readUrl$ = () =>
    combineLatest([
      this.contextService.currentMarket$(),
      this.contextService.currentLanguage$(),
    ]).pipe(
      map(
        ([market, languageCode]) =>
          `/api/search/markets/${market.programMarket}/filters/all?language=${languageCode}`
      )
    );
}

@Injectable({
  providedIn: "root",
})
export class VisibilityFilterService extends APISimpleService<FilterContainer> {
  constructor(http: HttpClient, config: VisibilityFilterServiceConfig) {
    super(http, config);
  }
}
