import { Subject } from "rxjs";
import { FilterContainer, PageSearchStateModel, PaginationInfo, SearchModel } from "..//models/models";

const initialSearchModel: SearchModel = {
  model: "",
  engines: [],
  criteria: "",
  pageIndex: 1,
};

export class SearchService {
  private readonly searchModelStore$$ = new Subject<SearchModel>();
  public searchModelState$ = this.searchModelStore$$.asObservable();

  private readonly filterModelStore$$ = new Subject<FilterContainer>();
  public filterModelState$ = this.filterModelStore$$.asObservable();

  private readonly searchingStateStore$$ = new Subject<boolean>();
  public searchingStateState$ = this.searchingStateStore$$.asObservable();

  private readonly pageSearchState$$ = new Subject<PageSearchStateModel>();
  public pageSearchState$ = this.pageSearchState$$.asObservable();

  public updateSearch(search: SearchModel) {
    this.searchModelStore$$.next(search);
  }



  public resetSearch() {
    this.searchModelStore$$.next(initialSearchModel);
  }

  public updateSearchingState(searching: boolean) {
    this.searchingStateStore$$.next(searching);
  }

  public updateFilter(filter: FilterContainer) {
    this.filterModelStore$$.next(filter);
  }

  public updatePageState(value: PaginationInfo) {
    this.pageSearchState$$.next(value);
  }


  public searchModel$() {
    return this.searchModelState$;
  }


  public filters$() {
    return this.filterModelState$;
  }

  public searching$() {
    return this.searchingStateState$;
  }


  public pagination$() {
    return this.pageSearchState$;
  }


}
