import { HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";

export class Filter {}

export class NoFilter extends Filter {}

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

export interface VbsState<T, F> {
  data: T;
  searchData: {
    filter: F;
    pagination: Pagination;
  };
  loading: boolean;
  error: boolean;
}

export class ResponseData {}

export abstract class BaseServiceConfig<
  D extends ResponseData,
  F extends Filter = NoFilter
> {
  initialFilter?: F;
  initialPagination?: Pagination;
  abstract initialData: D;
  public abstract baseUrl$: () => Observable<string>;
}

export enum ApiServiceStatus {
  NOT_TRIGGERED = "NOT_TRIGGERED",
  LOADING = "LOADING",
  ON_SUCCESS = "ON_SUCCESS",
  SUCCEEDED = "SUCCEEDED",
  ON_FAILURE = "ON_FAILURE",
  ERROR = "ERROR",
}

export class SimpleServiceConfig<T> {
  public readUrl$ = () => of("No path given for readUrl$");
  public deleteUrl$ = (value: T = null) => of("No path given for deleteUrl$");
  public updateUrl$ = (value: T = null) => of("No path given for updateUrl$");

  public updateRequestBody: any = (value: T = null) => value;
  public deleteDeleteBody: any = (value: T = null) => value;
}

export interface UpdateAPIServiceState<RQ, RS> {
  request: RQ | null;
  response: RS | HttpErrorResponse | null;
  state: ApiServiceStatus;
}


export interface APIServiceState<RC, RS> {
  request: RC | null;
  response: RS | HttpErrorResponse | null;
  state: ApiServiceStatus;
}




export abstract class UpdateAPIServiceConfig<RQ> {
  public abstract url$(request: RQ): Observable<string>
}





export abstract class APIServiceConfig<RC> {
  public abstract url$(request: RC): Observable<string>
}

export abstract class APIBodyServiceConfig<RC> {
  public abstract body$(request: RC): Observable<any>
}

