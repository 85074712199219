import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UpdateApiService } from "../generic/simple/update-api.service";
import { UpdateNoticeBodyConfigService } from "./update-notice-body-config.service";
import { UpdateNoticeConfigService } from "./update-notice-config.service";
import { Notification } from "../../../core/models/signalR/notification";



@Injectable({
  providedIn: 'root'
})
export class UpdateNoticeService extends UpdateApiService<Notification> {
  constructor(
    http: HttpClient,
    config: UpdateNoticeConfigService,
    body: UpdateNoticeBodyConfigService
  ) {
    super(http, config, body);
  }
}
