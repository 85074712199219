import { Injectable } from "@angular/core";
import { IRetryPolicy, LogLevel, RetryContext } from "@microsoft/signalr";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../../../environments/environment";
import { SignalRBuilderService } from "./signal-r-builder.service";

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  private startRetryMilliSec = 5000;
  private readonly loggLevel = environment.settings.signalR.showDebugInformation
    ? LogLevel.Information
    : LogLevel.Error;

  constructor(
    private readonly oAuthService: OAuthService,
    private readonly signalRBuilderService: SignalRBuilderService
  ) {}

  public async startConnection(market: string, startRetrySec = 5) {
    this.startRetryMilliSec = startRetrySec * 1000;

    const buildUrl = `/signalr/notification?market=${market}` ;
    this.signalRBuilderService.withUrl(buildUrl, {
      accessTokenFactory: () => this.oAuthService.getAccessToken(),
    });

    if (this.loggLevel === LogLevel.Information) {
      console.log(
        "SignalR: startConnection - withUrl",
        buildUrl,
        "providing token",
        this.oAuthService.getAccessToken()
      );
    }

    this.signalRBuilderService.withAutomaticReconnect(
      new SignalRLITRetryPolicy()
    );

    this.signalRBuilderService.configureLogging(this.loggLevel);

    await this.start();
  }

  private async start() {
    try {
      if (this.loggLevel === LogLevel.Information) {
        console.log("SignalR: start...");
      }
      await this.signalRBuilderService.start();
      if (this.loggLevel === LogLevel.Information) {
        console.log("SignalR: start...done");
      }
    } catch (err) {
      if (this.loggLevel === LogLevel.Information) {
        console.log("SignalR: start...fail", err);
      }
      setTimeout(() => this.start(), this.startRetryMilliSec);
    }
  }
}

export class SignalRLITRetryPolicy implements IRetryPolicy {
  nextRetryDelayInMilliseconds(retryContext: RetryContext): number {
    const nextRetry =
      (retryContext.previousRetryCount * retryContext.previousRetryCount + 1) *
      1000;
    console.log("SignalR: Reconnect in ", nextRetry / 1000, "sec");
    return nextRetry;
  }
}
