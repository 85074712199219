import { Injectable, OnDestroy } from "@angular/core";
import { UserService } from "../../../core/auth/user.service";
import { LocaleService } from "../../../core/locale/locale.service";
import { SubSink } from "subsink";
import { ApplicationStateService } from "../redux/state/application-state.service";

@Injectable({
  providedIn: "root",
})
export class UserFacadeService implements OnDestroy {
  private readonly subSink = new SubSink();

  constructor(
    private readonly state: ApplicationStateService,
    private readonly userService: UserService,
    private readonly localeService: LocaleService
  ) {
    this.setupUser();
  }

  public changeLanguage(market: string, language: string) {
    this.userService.saveLanguage(market, language);
    this.localeService.registerCulture(language);
  }

  private setupUser() {
    this.subSink.sink = this.userService.User$().subscribe((value) => {
      this.state.setUserName(value.userName);
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
