import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { environmentLoader as environmentLoaderPromise } from "./environments/environmentLoader";
import { VERSION } from "./environments/version";


console.log("Running version " + VERSION);

environmentLoaderPromise.then((env) => {
  if (env.production) {
    enableProdMode();
  }

  if (environment.loadDynamicValues) {
    environment.settings = env.settings;
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

});
