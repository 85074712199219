<div fxFlex class="dialog-container">
  <div fxLayout="row" fxLayoutAlign="center space-between" class="dialog-header">
    <div fxFlex>{{ translationService.get("browserWarningTitle") }}</div>
    <button class="close-button icon-black" (click)="close()">
     <mat-icon
     class="vcc-svgIcon"
     svgIcon="close"
     ></mat-icon>
    </button>
  </div>
  <div id="dialog-body">
  </div>
 </div>
