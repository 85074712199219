import { Injectable } from "@angular/core";
import { StoreConfigService } from "angular-simple-redux-lib";
import { LeadTimesState } from "../models/facade/leadtimes.state";

@Injectable({
  providedIn: "root",
})
export class LeadtimesStateConfigService extends StoreConfigService<LeadTimesState> {
  init = {
    list: null,
  };
}
