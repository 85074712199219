import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ContextService } from "../../application/shared/facade/context.service";
import { VERSION } from "../../../environments/version";
import { LogContext } from "../models/models.logging";

@Injectable({
  providedIn: "root",
})
export class LoggContextService {
  constructor(
    private readonly contextService: ContextService
  ) {}

  public logContext(): Observable<LogContext> {
    return combineLatest([
      this.contextService.currentMarket$(),
      this.contextService.currentUserEmail$(),
    ]).pipe(
      map(([market, email]) => {
        return {
          email,
          language: market.defaultLanguageCode,
          market: market.programMarket,
          partner: market.partner,
          role: this.contextService.userContext.roles,
          version: VERSION,
        } as LogContext;
      })
    );
  }
}
