import {
  Component,
  ComponentFactoryResolver,
  Injector, OnInit,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'app-root',
  template: `<ng-container #app></ng-container>`,
})
export class PlaceholderComponent implements OnInit {
  @ViewChild('app', { read: ViewContainerRef }) appContainer: ViewContainerRef;

  constructor(
    private readonly ctr: ComponentFactoryResolver,
    private readonly injector: Injector
  ) {}


  async ngOnInit(): Promise<void> {
    await this.loadAndBootstrapComponend()
  }

  public async loadAndBootstrapComponend() {
    const type = await this.loadComponent();
    const appComponentFactory = this.ctr.resolveComponentFactory(type);
    this.appContainer.createComponent(appComponentFactory, null, this.injector);
  }

  public async loadComponent() {
    const { AppComponent } = await import("./application/pages/application/app.component");
    return AppComponent as Type<any>;
  }
}
