import { Injectable } from '@angular/core';
import { AdditionalLoginState } from '../models/models.auth';

@Injectable({
  providedIn: 'root'
})
export class BrowserHelperService {

  constructor() { }

  public parameter(name: string) {
    let retVal: string = null;
    const index = window.location.search.indexOf(`${name}=`);
    if (index > -1) {
      retVal = window.location.search.substr(index + name.length + 1);
      const nextIndex = retVal.indexOf("&");
      if (nextIndex > -1) {
        retVal = retVal.substr(0, nextIndex);
      }
    }

    return retVal;
  }

  public createAdditionalLoginState(state: string) {
    try {
      const params = new URLSearchParams(state);
      const path = params.get("path");
      params.delete("path");

      const parameters = `?${params.toString()}`;

      return {
        path,
        parameters,
      } as AdditionalLoginState;
    } catch (e) {
      return new AdditionalLoginState();
    }
  }

  public getAdditionalLoginState() {
    const state = new URLSearchParams(window.location.search);
    state.append("path", window.location.pathname);
    return state.toString();
  }
}
