import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, noop, Observable, race } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import { ApplicationFacadeService } from "../../application/shared/facade/application-facade.service";
import { FeaturetoggleService } from "../api/feature-toggle/featuretoggle.service";
import { FiltersService } from "../api/filter/filters.service";
import { UserMarketsService } from "../api/market/user-markets.service";
import { VisibilityFilterService } from "../api/filter/visibility-filter.service";
import { BootstrapError, BootstrapState } from "../models/models.auth";
import { UserService } from "../auth/user.service";
import { ViewportService } from "../browser/viewport.service";
import { TranslationService } from "../label/translation.service";
import { LogService } from "../logging/log.service";
import { LoggContextService } from "../logging/logg-context.service";
import { ContextService } from "../../application/shared/facade/context.service";
import { UsersService } from "../../userManagement/shared/service/users.service";

let initialBootstrapState: BootstrapState = {
  loading: false,
  error: false,
  errorObject: null,
};

@Injectable({
  providedIn: "root",
})
export class BootstrapService {
  private readonly store$$ = new BehaviorSubject<BootstrapState>(
    initialBootstrapState
  );
  private readonly state$ = this.store$$.asObservable();

  public loading$: Observable<boolean> = this.state$.pipe(
    map((state) => state.loading)
  );
  public error$: Observable<boolean> = this.state$.pipe(
    map((state) => state.error)
  );
  public errorObject$: Observable<BootstrapError> = this.state$.pipe(
    map((state) => state.errorObject)
  );

  public vm$: Observable<BootstrapState> = combineLatest([
    this.loading$,
    this.error$,
    this.errorObject$,
  ]).pipe(
    map(([loading, error, errorObject]) => {
      return { loading, error, errorObject };
    })
  );

  constructor(
    private readonly applicationFacadeService: ApplicationFacadeService,
    private readonly translationService: TranslationService,
    private readonly loggContextService: LoggContextService,
    private readonly logService: LogService,
    private readonly viewport: ViewportService,
    private readonly featuretoggleService: FeaturetoggleService,
    private readonly userService: UserService,
    private readonly userMarkets: UserMarketsService,
    private readonly filterService: FiltersService,
    private readonly visibilityFilterService: VisibilityFilterService,
    private readonly contextService: ContextService,
    private readonly usersService: UsersService
  ) {
    this.viewport.setViewport();
  }

  public async bootstrap(): Promise<void> {
    this.logService.trackPageViews();
    return new Promise(async (resolve, reject) => {
      await this.userService
        .authenticate()
        .then(async () => {
          this.logService.setUser(this.userService.user.id);
        })
        .then(async () => {
          await this.loadFeatureToggles();
        })
        .then(async () => {
          await this.loadUserMarkets();
          await this.applicationFacadeService.market.setCurrentMarket();
        })
        .then(async () => {
          await this.applicationFacadeService.market
            .status()
            .initSignalR()
            .catch(noop);
        })
        .then(async () => {
          await this.bootstrapFilters();
        })
        .then(async () => {
          await this.bootstrapInitialLanguage();
        })
        .then(async () => {
          if (this.contextService.userContext.hasNscAdminRole) {
            await this.bootstrapUsersList();
          }
        })
        .then(async () => {
          if (this.contextService.userContext.hasNscAdminRole) {
            await this.bootstrapVisibilityFilters();
          }
        })
        .then(() => {
          this.loggContextService
            .logContext()
            .pipe(take(1))
            .subscribe((logContext) => {
              this.logService.setContext(logContext);
            });
          this.completed();
          resolve();
        })
        .catch((error: BootstrapError) => {
          this.updateError(error);
          reject(error);
          console.error(error);
        });
    });
  }

  private async loadFeatureToggles(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.featuretoggleService
        .load()
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject(new BootstrapError(104, "Failed to load featureToggles"));
        });
    });
  }

  private async bootstrapFilters(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.filterService.error$
        .pipe(take(2))
        .subscribe((x) => this.setRejectFilterError(x, reject));
      this.filterService
        .filters$()
        .pipe(take(1))
        .subscribe((x) => resolve());

      combineLatest([
        this.contextService.currentMarket$(),
        this.contextService.currentLanguage$(),
      ])
        .pipe(
          tap(([currentMarket, selectedLanguage]) => {
            this.filterService.updateFilters(
              currentMarket.programMarket,
              selectedLanguage
            );
          }),
          take(1)
        )
        .subscribe();
    });
  }

  private async loadUserMarkets(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      race(
        this.userMarkets.data$().pipe(
          tap((userMarkets) => {
            if (userMarkets?.markets?.length ?? 0) {
              resolve();
            } else {
              reject(
                new BootstrapError(
                  102,
                  "User does not have any roles and partner"
                )
              );
            }
          })
        ),
        this.userMarkets.error$().pipe(
          filter((error) => error === true),
          tap(() => {
            reject(new BootstrapError(104, "Failed to load UserMarkets"));
          })
        )
      )
        .pipe(take(1))
        .subscribe();

      this.userMarkets.read();
    });
  }

  private async bootstrapVisibilityFilters(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      race(
        this.visibilityFilterService.data$().pipe(tap(() => resolve())),
        this.visibilityFilterService.error$().pipe(
          filter((error) => error === true),
          tap(() => {
            reject(
              new BootstrapError(103, "Failed to load visibility filters")
            );
          })
        )
      )
        .pipe(take(1))
        .subscribe();

      this.visibilityFilterService.read();
    });
  }

  private async bootstrapInitialLanguage(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.contextService
        .currentMarket$()
        .pipe(
          tap(async (market) => {
            await this.translationService
              .setLanguage(market.programMarket, this.userService.languageCode)
              .then(() => resolve())
              .catch((err) => {
                reject(new BootstrapError(101, err));
              });
          })
        )
        .pipe(take(1))
        .subscribe();
    });
  }

  private async bootstrapUsersList(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.contextService
        .currentMarket$()
        .pipe(
          tap(async (market) => {
            await this.usersService
              .setUsersList(market.programMarket, this.userService.languageCode)
              .then(() => resolve())
              .catch((err) => {
                reject(new BootstrapError(101, err));
              });
          })
        )
        .pipe(take(1))
        .subscribe();
    });
  }

  private updateError(errorObject: BootstrapError): void {
    this.updateState({
      errorObject: { ...errorObject },
      loading: false,
      error: true,
    });
  }

  private completed(): void {
    this.updateState({
      errorObject: null,
      loading: false,
      error: false,
    });
  }

  private updateState(state: BootstrapState) {
    initialBootstrapState = state;
    this.store$$.next(initialBootstrapState);
  }

  private setRejectFilterError(isError: boolean, reject: any) {
    if (isError === true) {
      reject(new BootstrapError(103, "Failed to load filters"));
    }
  }
}
