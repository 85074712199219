import { HttpClient } from "@angular/common/http";
import { lastValueFrom, Observable, of } from "rxjs";
import { catchError, shareReplay, tap } from "rxjs/operators";

export class APISingleService<T> {
  private value: T;
  constructor(private readonly http: HttpClient, protected base: string) {}

  private readonly api$ = this.http.get<T>(this.base).pipe(
    shareReplay(1),
    tap((value) => (this.value = value)),
    catchError(() => of(null))
  );

  public async load() {
    await lastValueFrom(this.api$).then((v) => {
      if (v === null) {
        throw new Error();
      }
    });
  }

  public get(): Observable<T | null> {
    return this.api$;
  }

  public getValue(): T {
    return this.value;
  }
}
