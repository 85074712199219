import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EffectOrReducer } from "angular-simple-redux-lib";
import { catchError, mergeMap, of, tap, withLatestFrom } from "rxjs";
import { ContextService } from "../../facade/context.service";
import { ApplicationActions } from "../../models/redux/actions/application-actions";
import {
  ChangeDistributionPointError,
  ChangeDistributionPointPayload,
} from "../../models/redux/actions/change-distribution-point-payload";
import { ApplicationActionsService } from "../actions/application-actions.service";

@Injectable({
  providedIn: "root",
})
export class UpdateDistributionPointEffectService extends EffectOrReducer {
  constructor(
    private readonly action: ApplicationActionsService,
    private readonly http: HttpClient,
    private readonly contextService: ContextService
  ) {
    super();
    this.init();
  }

  protected actionObservable$() {
    return this.action
      .on<ChangeDistributionPointPayload>(
        ApplicationActions.UPDATE_DISTRIBUTION_POINT
      )
      .pipe(
        withLatestFrom(this.contextService.currentMarket$()),
        mergeMap(([changeDistributionPointPayload, currentMarket]) => {
          return this.http
            .put(
              `/api/users/markets/${currentMarket.programMarket}/partner/distributionpoints/${changeDistributionPointPayload.newValue}`,
              {
                code: changeDistributionPointPayload.newValue,
              }
            )
            .pipe(
              tap({
                next: () => {
                  this.action.dispatch(
                    ApplicationActions.UPDATE_DISTRIBUTION_POINT_SUCCEDED,
                    changeDistributionPointPayload
                  );
                },
                error: (httpErrorResponse: HttpErrorResponse) => {
                  this.action.dispatch(
                    ApplicationActions.UPDATE_DISTRIBUTION_POINT_FAILED,
                    {
                      changeDistributionPointPayload,
                      httpErrorResponse,
                    } as ChangeDistributionPointError
                  );
                },
              }),
              catchError((err) => {
                console.log(err);
                return of();
              })
            );
        })
      );
  }
}
