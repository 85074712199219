import { Injectable } from "@angular/core";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { Router, ResolveEnd, ActivatedRouteSnapshot } from "@angular/router";
import { filter } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { LogContext } from "../models/models.logging";

export enum Events {
  SET_DISTRIBUTIONPOINT = "SET_DISTRIBUTIONPOINT",
  SET_MANUALINDICATION = "SET_MANUALINDICATION",
  REMOVE_MANUALINDICATION = "REMOVE_MANUALINDICATION",
  SET_MODELITEMVISIBILITY = "SET_MODELITEMVISIBILITY",
  SET_OPTIONITEMVISIBILITY = "SET_OPTIONITEMVISIBILITY",
  SET_SHUTDOWNMARKET = "SET_SHUTDOWNMARKET",
  REMOVE_SHUTDOWNMARKET = "REMOVE_SHUTDOWNMARKET",
  SET_NOTICE = "SET_NOTICE",
  REMOVE_NOTICE = "REMOVE_NOTICE",
  PUBLISH_MODEL_USEMPS = "PUBLISH_MODEL_USEMPS",
  UNPUBLISH_MODEL_USEMPS = "UNPUBLISH_MODEL_USEMPS",
}
@Injectable({
  providedIn: "root",
})
export class LogService {
  private appInsights: ApplicationInsights = null;

  constructor(private readonly router: Router) {
    this.setup(environment.settings.appInsights);
  }

  setup(settings: any) {
    this.appInsights = new ApplicationInsights(settings);
    this.appInsights.loadAppInsights();
  }

  trackPageViews() {
    // Track page views with app insights
    this.router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.appInsights.trackPageView({
            name: `${activatedComponent.name} ${this.getRouteTemplate(
              event.state.root
            )}`,
            uri: event.urlAfterRedirects,
          });
        }
      });
  }

  setContext(context: LogContext) {
    if (!this.appInsights) {
      return;
    }

    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item.properties = item.properties || {};

      item.properties["Market"] = context.market;
      item.properties["Language"] = context.language;
      item.properties["Role"] = context.role;
      item.properties["Partner"] = context.partner;
      item.properties["Version"] = context.version;
    });
  }

  setUser(oid: string) {
    if (!this.appInsights) {
      return;
    }

    this.appInsights.setAuthenticatedUserContext(oid);
  }

  logPageView(name?: string, uri?: string) {
    if (!this.appInsights) {
      return;
    }

    this.appInsights.trackPageView({ name, uri });
  }

  logError(message: string) {
    if (!this.appInsights) {
      return;
    }

    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Error,
    });
  }

  logTrace(message: string) {
    if (!this.appInsights) {
      return;
    }

    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Verbose,
    });
  }

  logException(exception: Error, severityLevel?: number) {
    if (!this.appInsights) {
      return;
    }

    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logWarning(message: string) {
    if (!this.appInsights) {
      return;
    }

    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Warning,
    });
  }

  logEvent(name: Events, data?: any) {
    this.appInsights.trackEvent({ name }, data);
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }
  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = "";
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
