import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { Pagination } from "../../../core/models/models.api.generic";
import { StoreService } from "angular-simple-redux-lib";
import { LeadTimesState } from "../models/facade/leadtimes.state";
import { LeadtimesFilter } from "../models/models";
import { LeadtimesStateConfigService } from "./leadtimes-state-config.service";

@Injectable()
export class LeadtimesStateService extends StoreService<LeadTimesState> {
  private readonly showShutdownWarning$ = new ReplaySubject<boolean>(1);
  private readonly searchModel$ = new ReplaySubject<{
    filter: LeadtimesFilter;
    pagination: Pagination;
  }>(1);

  private readonly isPno12Search$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly config: LeadtimesStateConfigService) {
    super(config);
  }

  setShowShutdownWarning(value: boolean) {
    this.showShutdownWarning$.next(value);
  }

  getShowShutdownWarning$() {
    return this.showShutdownWarning$.asObservable();
  }

  setSearchModel(search: { filter: LeadtimesFilter; pagination: Pagination }) {
    this.searchModel$.next(search);
  }

  getSearchModel$() {
    return this.searchModel$.asObservable();
  }

  setIsPno12Search(isPno12Search: boolean) {
    this.isPno12Search$.next(isPno12Search);
  }

  getIsPno12Search$() {
    return this.isPno12Search$.asObservable();
  }
}
