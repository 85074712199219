import { Injectable } from "@angular/core";
import { VisibilityStateService, VisibilityStateConfigService } from "../visibility-state.service";
import { DisplayManualIndicationDialogService } from "./scenario/display-manual-indication-dialog.service";
import { VisibilityState } from "../../../../model/models";

@Injectable({
  providedIn: "any",
})
export class VisibilityStateTestService extends VisibilityStateService {
  constructor(
    private readonly config: VisibilityStateConfigService,
    private readonly displayManualIndicationDialog:DisplayManualIndicationDialogService
  ) {
    super(config);
    console.log("LOGG: [Test] VisibilityStateTestService - constructor");
  }

  protected updateState(acc: VisibilityState, newVal: VisibilityState) {
    return { ...super.updateState(acc, newVal), ...this.showState() };
  }

  private showState() {
    if(this.displayManualIndicationDialog.active()) {
      return this.displayManualIndicationDialog.state()
    }
    return {};
  }
}
