import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    const isApiCall = request.url.startsWith("/api");
    if (isApiCall) {
      const modifiedReq = request.clone({
        headers: request.headers
          .set("Cache-Control", "no-cache")
          .set("Pragma", "no-cache"),
      });

      return next.handle(modifiedReq);
    }

    return next.handle(request);
  }
}
