import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

import { MessageService } from "src/app/ui/messages/message.service";
import { MessageType } from "src/app/ui/messages/model";
import { ErrorService } from "../../../core/error/error.service";
import { LogService } from "../../../core/logging/log.service";
import { UsersMapperService } from "../mapper/users.mapper.service";
import { MappedUserList, User } from "../model/users.service.models";
import { UsersFacadeService } from "./users-facade.service";
import { UsersStateService } from "./users-state.service";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private readonly localePath = "/api/users";

  private readonly usersData$$ = new ReplaySubject<MappedUserList[]>();
  public usersData$ = this.usersData$$.asObservable();

  constructor(
    private readonly httpService: HttpClient,
    private readonly logService: LogService,
    private readonly errorService: ErrorService,
    private readonly usersMapperService: UsersMapperService,
    private readonly messagesService: MessageService,
    private readonly state: UsersStateService,
    private readonly facade : UsersFacadeService
  ) {}

  //***** PRIVATE FUNCTION *****/

  private getUsersList(marketCode: string): Promise<any> {
    const url = `${this.localePath}/${marketCode}`;

    return new Promise<any>((resolve, reject) => {
      this.httpService.get(url).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
        complete: () => {}
      });
    });
  }

  private setAndEmitUsersList(users: any) {
    this.usersData$$.next(
      this.usersMapperService.mapToState(users)
    );
    this.state.update(
      "mappedUser",
      this.usersMapperService.mapToState(users)
    );
  }

  private showSnackBarMessage(message: string, messageType: MessageType) {
    this.messagesService.showSnackBar(
      message,
      "Dismiss",
      messageType,
      "top",
      8000
    );
  }

  //***** PUBLIC FUNCTION *****/

  public setUsersList(marketCode: string, languageCode: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getUsersList(marketCode)
        .then((users) => {
          this.setAndEmitUsersList(users);
          this.state.update("users", users);
          resolve();
        })
        .catch((_) => {
          this.logService.logError(
            `Could not set users list for market ${marketCode} and language ${languageCode}.`
          );
          this.errorService.updateError(true);
          reject(_);
        });
    });
  }

  public addNewUser(marketCode: string, user: User) {
    const url = `${this.localePath}/${marketCode}`;
    this.httpService.post(url, user).subscribe({
      next: () => {
        this.setUsersList(marketCode, '');
        this.showSnackBarMessage("User created successfully", MessageType.success);
        this.facade.closeAddUserDialog();
      },
      error: (error) => {
        this.showSnackBarMessage(error.error.errorMessage ? error.error.errorMessage : 'Error occurred while creating user', MessageType.error);
        this.facade.closeAddUserDialog();
      },
    });
  }

  public deleteUser(marketCode: string, email: string) {
    const url = `${this.localePath}/${marketCode}/${email}`;
    this.httpService.delete(url).subscribe({
      next: () => {
        this.setUsersList(marketCode, '');
        this.showSnackBarMessage("User deleted successfully", MessageType.success);
        this.facade.closeDeleteUserDialog();
        this.facade.closeEditUserDialog();
      },
      error: (error) => {
        this.showSnackBarMessage(error.error.errorMessage ? error.error.errorMessage : 'Error occurred while deleting user', MessageType.error);
        this.facade.closeDeleteUserDialog();
      },
    });
  }

  public editUser(marketCode: string, user: User) {
    const url = `${this.localePath}/${marketCode}/update`;
    this.httpService.put(url, user).subscribe({
      next: () => {
        this.setUsersList(marketCode, '');
        this.showSnackBarMessage("User was edited successfully", MessageType.success);
        this.facade.closeEditUserDialog();
      },
      error: (error) => {
        this.showSnackBarMessage(error.error.errorMessage  ? error.error.errorMessage : 'Error occurred while editing the user', MessageType.error);
        this.facade.closeEditUserDialog();
      },
    });
  }
}
