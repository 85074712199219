import { Injectable } from "@angular/core";
import { ApplicationStateService } from "src/app/application/shared/redux/state/application-state.service";
import { DialogStatus } from "../model/users.service.models";
import { UsersStateService } from "./users-state.service";

@Injectable({
  providedIn: "root",
})
export class UsersFacadeService {
  constructor(
    private readonly state: UsersStateService,
    private readonly applicationState: ApplicationStateService
  ) {}

  public usersData$() {
    return this.state.get$("users");
  }

  public addUsersDialogStatus$() {
    return this.state.get$("addUsersDialogStatus");
  }

  public editUsersDialogStatus$() {
    return this.state.get$("editUsersDialogStatus");
  }

  public editingUser$() {
    return this.state.get$("editingUser");
  }

  public deleteUserDialogStatus$() {
    return this.state.get$("deleteUserDialogStatus");
  }

  //ACTIONS

  public openAddUserDialog() {
    this.state.update("addUsersDialogStatus", DialogStatus.open);
  }

  public closeAddUserDialog() {
    this.state.update("addUsersDialogStatus", DialogStatus.closed);
  }

  public openDeleteUserDialog() {
    this.state.update("deleteUserDialogStatus", DialogStatus.open);
  }

  public closeDeleteUserDialog() {
    this.state.update("deleteUserDialogStatus", DialogStatus.closed);
  }

  public getCurrentMarket() {
    return this.applicationState.getCurrentMarket$();
  }

  public openEditUserDialog() {
    this.state.update("editUsersDialogStatus", DialogStatus.open);
  }

  public closeEditUserDialog() {
    this.state.update("editUsersDialogStatus", DialogStatus.closed);
  }

  public setEditingUser(user) {
    return this.state.update("editingUser", user);
  }
}
