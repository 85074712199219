import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CustomBreakPointsProvider } from "./breakpoints/custom-breakpoints";
import { CustomFlexDirective } from "./breakpoints/custom-fxflex.directive";
import { CustomClassDirective as CustomClassDirective } from "./breakpoints/custom-ngclass.directive";
import { CustomShowHideDirective } from "./breakpoints/custom-show-hide.directive";

@NgModule({
  declarations: [
    CustomFlexDirective,
    CustomShowHideDirective,
    CustomClassDirective,
  ],
  imports: [CommonModule],
  exports: [CustomFlexDirective, CustomShowHideDirective, CustomClassDirective],
  providers: [CustomBreakPointsProvider],
})
export class FlexModule {}
