import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { DateRangeYearDecoratorService } from "../../../../../core/formatters/date-range-year-decorator.service";
import { DateRangeYearRemoveStartDateForSameMonthDecoratorService } from "../../../../../core/formatters/date-range-year-remove-start-date-for-same-month-decorator.service";
import { TranslationService } from "../../../../../core/label/translation.service";
import { EnvironmentService } from "../../../../../core/utils/environment.service";
import {
  AllModels,
  Model,
  ModelPart,
  SalesVersion as InternalSalesVersion,
} from "./models/model-mapped";
import { SelectedModel } from "./models/models";
import {
  AverageLeadtimes,
  ItemBase,
  SalesVersion as ExternalSalesVersion,
} from "./models/models-api";

@Injectable()
export class AverageLeadtimesMapperService {
  constructor(
    private readonly dateRangeBuilderFormatDecoratorService: DateRangeYearDecoratorService,
    private readonly removeStartDateForLongFormatAndSameMonthDecoratorService: DateRangeYearRemoveStartDateForSameMonthDecoratorService,
    private readonly translationService: TranslationService,
    private readonly environment: EnvironmentService
  ) {}
  mapToAllModels(averageLeadtime: AverageLeadtimes): Observable<AllModels> {
    return of({
      models: averageLeadtime.items.map((item) => {
        const hasLeadtimes = !!item.startIndication && !!item.endIndication;
        const showContinent =
          averageLeadtime.items.filter((value) => value.code === item.code)
            .length > 1;
        return {
          code: item.code,
          description: item.description,
          hasVbsWithoutCapacity: !item.allVbsHaveCapacity && hasLeadtimes,
          hasLimitedAvailability:
            item.startIndication && item.endIndication ? false : true,
          hasMultipleModelYears:
            Object.keys(item.engines.groupBy("modelYearText")).length > 1 ||
            Object.keys(item.salesVersions.groupBy("modelYearText")).length > 1,
          continent: showContinent ? item.continent : null,
          deliveryRange:
            item.startIndication && item.endIndication
              ? this.dateRangeBuilderFormatDecoratorService
                  .addMonthFormat(
                    this.environment.get().settings.monthDateRangeFormat
                  )
                  .addYearFormat(
                    this.environment.get().settings.yearDateRangeFormat
                  )
                  .addStartDate(item.startIndication)
                  .addEndDate(item.endIndication)
                  .build()
                  .toUpperCase()
              : this.translationService.get("limitedAvailability"),
          engines: item.engines.map((engine) => {
            return this.mapItemBaseToModelPart(engine);
          }),
          salesVersions: item.salesVersions.map((salesVersion) => {
            return this.mapItemBaseToSalesVersion(salesVersion);
          }),
        };
      }),
    });
  }
  private mapItemBaseToSalesVersion(
    salesVersion: ExternalSalesVersion
  ): InternalSalesVersion {
    return {
      ...this.mapItemBaseToModelPart(salesVersion),
      theme: salesVersion.theme,
    };
  }

  private mapItemBaseToModelPart(engine: ItemBase): ModelPart {
    const hasLeadtimes = !!engine.startIndication && !!engine.endIndication;
    return {
      code: engine.code,
      description: engine.description,
      hasVbsWithoutCapacity: !engine.allVbsHaveCapacity && hasLeadtimes,
      deliveryRange:
        engine.startIndication && engine.endIndication
          ? this.removeStartDateForLongFormatAndSameMonthDecoratorService
              .addMonthFormat(
                this.environment.get().settings.monthDateRangeFormat
              )
              .addYearFormat(
                this.environment.get().settings.yearDateRangeFormat
              )
              .addStartDate(engine.startIndication)
              .addEndDate(engine.endIndication)
              .build()
              .toUpperCase()
          : this.translationService.get("limitedAvailability"),
      modelYearText: engine.modelYearText,
    };
  }

  mapToSelectedModel(model: Model): SelectedModel {
    return {
      code: model.code,
      description: model.description,
      continent: model.continent,
      engines: model.engines,
      salesVersion: model.salesVersions,
    };
  }
}
