import { Inject, Injectable, OnDestroy } from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { MatDialog } from "@angular/material/dialog";
import { SESSION_STORAGE } from "@ng-web-apis/common";
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  ReplaySubject,
  tap,
} from "rxjs";
import { SubSink } from "subsink";
import { BrowserAgentService } from "../../../core/browser/browser-agent.service";
import { DialogIeWarningComponent } from "../../components/dialog-ie-warning/dialog-ie-warning.component";
import { ApplicationStateService } from "../redux/state/application-state.service";

@Injectable({
  providedIn: "root",
})
export class BrowserFacadeService implements OnDestroy {
  private readonly subSink = new SubSink();
  private readonly isMobile$$: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  private readonly isDesktop$$: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);

  private readonly showSearch$$: ReplaySubject<void> = new ReplaySubject<void>(
    1
  );
  constructor(
    private readonly state: ApplicationStateService,
    public readonly dialog: MatDialog,
    private readonly mediaObserver: MediaObserver,
    private readonly browserAgent: BrowserAgentService,
    @Inject(SESSION_STORAGE) readonly sessionStorage: Storage
  ) {
    this.setupMediaChange();
    this.setupDialog();
  }

  public displayIeWarning() {
    const hasWarningAlreadyBeenDisplayed = this.sessionStorage.getItem(
      "hasWarningAlreadyBeenDisplayed"
    );

    if (this.browserAgent.IsIE11 && !hasWarningAlreadyBeenDisplayed) {
      this.sessionStorage.setItem("hasWarningAlreadyBeenDisplayed", "yes");
      this.dialog.open(DialogIeWarningComponent, {
        panelClass: "ie-warning-dialog",
      });
    }
  }

  private setupDialog() {
    this.dialog.afterOpened.subscribe((value) => {
      this.state.setDialogStatus(value.id, true);
      value.afterClosed().subscribe(() => {
        this.state.setDialogStatus(value.id, false);
      });
    });
  }

  private setupMediaChange() {
    this.subSink.sink = this.mediaObserver
      .asObservable()
      .pipe(
        map(
          (breakPointList) =>
            breakPointList.findIndex(
              (mediaChange) => mediaChange.mqAlias === "mobile"
            ) > -1
        ),
        tap((isMobile) => {
          this.isMobile$$.next(isMobile);
          this.isDesktop$$.next(!isMobile);
        })
      )
      .subscribe();
  }

  public isMobile$(): Observable<boolean> {
    return this.isMobile$$.asObservable();
  }

  public isDesktop$(): Observable<boolean> {
    return this.isDesktop$$.asObservable();
  }

  public hideSearch$(): Observable<void> {
    return this.isDesktop$().pipe(
      distinctUntilChanged(),
      filter((isDesktopMode) => isDesktopMode),
      map(() => {})
    );
  }

  public showSearchDialog() {
    this.showSearch$$.next();
  }

  public searchDialogIsOpen$(): Observable<boolean> {
    return this.state.getSearchDialogIsOpen$();
  }
  public settingsDialogIsOpen$(): Observable<boolean> {
    return this.state.getSettingsDialogIsOpen$();
  }

  public showSearch$(): Observable<void> {
    return this.showSearch$$.asObservable();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
