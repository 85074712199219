import { UserInfo } from "angular-oauth2-oidc/types";

export class User {
  private constructor(
    private readonly _sid: string,
    private readonly _firstName: string,
    private readonly _lastName: string,
    private readonly _email: string
    ) {}

  public get id() {
    return this._sid;
  }

  public get userName() {
    return `${this._firstName} ${this._lastName}`;
  }

  public get email(): string {
    return this._email;
  }

  public static fromValues(
    id: string,
    firstName: string,
    lastName: string,
    email: string
  ) {
    return new User(id, firstName, lastName, email);
  }

  public static fromUserInfo(userInfo: UserInfo) {
    const id = userInfo.sub;
    const familyName = (userInfo && userInfo.family_name) || "";
    const givenName = (userInfo && userInfo.given_name) || "";


    return User.fromValues(
      id,
      givenName,
      familyName,
      userInfo.email
    );
  }
}



export class BootstrapError {
  constructor(public code: number, public message: string) {}

  public static Create(code: number, errorObject: any = "") {
    const error = JSON.stringify(errorObject, undefined, 4);
    return new BootstrapError(code, error);
  }
}

export interface BootstrapState {
  loading: boolean;
  error: boolean;
  errorObject: BootstrapError;
}

export class AdditionalLoginState {
  path = "";
  parameters = "";
}
