import { Injectable, OnDestroy } from "@angular/core";
import { merge, Observable, Subject } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { EmitEvent } from "../../models/signalR/emit.event";
import { Events } from "../../models/signalR/events";
import { Notification } from "../../models/signalR/notification";
import { SignalRBuilderService } from "./signal-r-builder.service";

@Injectable({
  providedIn: "root",
})
export class SignalRMediatorService implements OnDestroy {
  private readonly subject$ = new Subject<any>();

  constructor(private readonly signalRBuilderService: SignalRBuilderService) {}

  private readonly actionShutdown$ = this.signalRBuilderService
    .addListener<Notification>("shutdown")
    .pipe(tap((value) => this.emit(new EmitEvent(Events.Shutdown, value))));

  private readonly actionNotice$ = this.signalRBuilderService
    .addListener<Notification>("notice")
    .pipe(tap((value) => this.emit(new EmitEvent(Events.Notice, value))));

  private emit(event: EmitEvent) {
    this.subject$.next(event);
  }

  public on<T>(event: Events, action: (arg: T) => void): Observable<T> {
    return this.subject$
      .pipe(
        filter((e: EmitEvent) => {
          return e.name === event;
        }),
        map((e: EmitEvent) => {
          return e.value as T;
        }),
        tap((value) => action(value))
      )
  }

  private readonly subscriptions = merge(
    this.actionShutdown$,
    this.actionNotice$
  ).subscribe();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
