import { Injectable } from "@angular/core";
import { EffectOrReducer } from "angular-simple-redux-lib";
import { tap } from "rxjs";
import { ApplicationActions } from "../../models/redux/actions/application-actions";
import { ChangeDistributionPointPayload } from "../../models/redux/actions/change-distribution-point-payload";
import { ApiStatus } from "../../models/redux/store/api-status";
import { ApplicationActionsService } from "../actions/application-actions.service";
import { ApplicationStateService } from "../state/application-state.service";

@Injectable({
  providedIn: "root",
})
export class UpdateDistributionPointSucceededReducerService extends EffectOrReducer {
  constructor(
    private readonly state: ApplicationStateService,
    private readonly actionService: ApplicationActionsService
  ) {
    super();
    this.init();
  }

  protected actionObservable$() {
    return this.actionService
      .on<ChangeDistributionPointPayload>(
        ApplicationActions.UPDATE_DISTRIBUTION_POINT_SUCCEDED
      )
      .pipe(
        tap(() => {
          this.state.update("distributionPointStatus", ApiStatus.SUCCEEDED);
        })
      );
  }
}
