import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DefaultRedirectGuardService } from "./core/guard/default-redirect-guard.service";
import { NgxFeatureToggleGuardForSlotsService } from "./core/guard/NgxFeatureToggleGuardForSlotsService";
import { NscAdminAuthGuardService } from "./core/guard/nscAdminAuthGuard.service";
import { NscAuthGuardService } from "./core/guard/nscAuthGuard.service";
import { OverviewFeaturetoggleGuardService } from "./core/guard/overview-featuretoggle-guard.service";
import { RetailerAuthGuardService } from "./core/guard/retailerAuthGuard.service";
import { SalesTypesMarketEnabledGuard } from "./core/guard/sales-types-market-enabled.guard";

const routes: Routes = [
  {
    path: "leadtimes",
    loadChildren: () =>
      import("./leadtime/leadtime.module").then((m) => m.LeadTimesModule),
    canLoad: [RetailerAuthGuardService],
  },
  {
    path: "visibility",
    loadChildren: () =>
      import("./visibility/visibility.module").then((m) => m.VisibilityModule),
    canLoad: [NscAdminAuthGuardService],
  },
  {
    path: "search",
    redirectTo: "leadtimes",
  },
  {
    path: "translation",
    loadChildren: () =>
      import("./translation/translation.module").then(
        (m) => m.TranslationModule
      ),
    canLoad: [NscAdminAuthGuardService],
  },
  {
    path: "slots",
    loadChildren: () =>
      import("./slots/slots.module").then((m) => m.SlotsModule),
    canActivate: [NgxFeatureToggleGuardForSlotsService],
    canLoad: [NscAdminAuthGuardService],
  },
  {
    path: "overview",
    loadChildren: () =>
      import("./overview/overview.module").then((m) => m.OverviewModule),
    canActivate: [OverviewFeaturetoggleGuardService],
    canLoad: [NscAuthGuardService],
  },
  {
    path: "market-Configuration",
    loadChildren: () =>
      import("./marketConfiguration/market-configuration.module").then(
        (m) => m.MarketConfigurationModule
      ),
    canActivate: [SalesTypesMarketEnabledGuard],
    canLoad: [NscAdminAuthGuardService],
  },
  {
    path: "user-Management",
    loadChildren: () =>
      import("./userManagement/users.module").then((m) => m.UsersModule),
    canLoad: [NscAdminAuthGuardService],
  },
  {
    path: "**",
    children: [],
    canActivate: [DefaultRedirectGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
