import { Injectable } from "@angular/core";
import { combineLatest, map } from "rxjs";
import { ContextService } from "../../../application/shared/facade/context.service";
import { APIServiceConfig } from "../../models/models.api.generic";

import { Notification } from "../../models/signalR/notification";

@Injectable({
  providedIn: "root",
})
export class UpdateShutdownConfigService extends APIServiceConfig<
  Notification
> {
  url$ = (item: Notification) =>
    combineLatest([this.contextService.currentMarket$()]).pipe(
      map(
        ([market]) =>
          `/api/markets/${market.programMarket}/downtime`
      )
    );

  constructor(private readonly contextService: ContextService) {
    super();
  }
}
