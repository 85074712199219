import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Notification } from "../../models/signalR/notification";
import { UpdateApiService } from "../generic/simple/update-api.service";
import { UpdateShutdownBodyConfigService } from "./update-shutdown-body-config.service";
import { UpdateShutdownConfigService } from "./update-shutdown-config.service";

@Injectable({
  providedIn: "root",
})
export class UpdateShutdownService extends UpdateApiService<Notification> {
  constructor(
    http: HttpClient,
    config: UpdateShutdownConfigService,
    body: UpdateShutdownBodyConfigService
  ) {
    super(http, config, body);
  }
}
