import { Injectable } from "@angular/core";
import { ApiServiceStatus } from "../../../../../../../core/models/models.api.generic";
import { BrowserHelperService } from "../../../../../../../core/utils/browser-helper.service";
import { ManualIndicationDialogStatus } from "../../../../../model/models";
import { ModelManualIndicationItem } from "../../../../../model/redux/state/vbsFlatList/items/model/model.manual.indication";

@Injectable({
  providedIn: "any",
})
export class DisplayManualIndicationDialogService {
  constructor(private readonly browserHelper: BrowserHelperService) {}

  public state() {
    return {
      manualIndicationDialogStatus: ManualIndicationDialogStatus.open,
      manualIndicationData: {
        currentIndication: "date",
        manualIndication: {
          manualDateRange: {
            startDate: new Date(),
            endDate: new Date(),
          },
          manualTexts: [
            {
              language: "Finnish",
              languageCode: "fi",
              message: "Team badger is here to rule.",
            },
          ],
        },
        modelYear: 2025,
        pno12: "23456879456",
      } as ModelManualIndicationItem,
      manualIndicationDataUpdateStatus: ApiServiceStatus.NOT_TRIGGERED,
    };
  }

  public active() {
    return (
      this.browserHelper.parameter("show") === "displaymanualindicationdialog"
    );
  }
}
