import { Directive } from "@angular/core";
import { FlexDirective } from "@angular/flex-layout";

const selector = `[fxFlex],
[fxFlex.mobile], [fxFlex.desktop]
[fxFlex.tablet],
[fxFlex.lt-desktop],
[fxFlex.gt-mobile], [fxFlex.gt-desktop]
`;

const inputs = [
  "fxFlex",
  "fxFlex.mobile",
  "fxFlex.desktop",
  "fxFlex.tablet",
  "fxFlex.lt-desktop",
  "fxFlex.gt-mobile",
  "fxFlex.gt-desktop",
];
@Directive({ selector, inputs })
export class CustomFlexDirective extends FlexDirective {
  protected inputs = inputs;
}
