import { DistributionPoint } from "../../../../../core/models/models.api";

export function mapToDistributionPoints(
  distributionPoints: DistributionPoint[],
  currentDistributionPoint: string
) {
  return distributionPoints.map((distributionPoint) => {
    if (distributionPoint.code === currentDistributionPoint) {
      return { ...distributionPoint, isCurrent: true };
    } else {
      return { ...distributionPoint, isCurrent: false };
    }
  });
}
