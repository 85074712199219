import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import {
  ApiServiceStatus,
  Pagination,
} from "../../../../../core/models/models.api.generic";
import { StoreConfigService, StoreService } from "angular-simple-redux-lib";
import {
  VbsListData,
  VbsListSection,
  VbsListParent,
  VbsListChild,
} from "../../../../../ui/list/models";
import {
  ManualIndicationDialogStatus,
  PutState,
  ModelStates,
  VisibilityState,
} from "../../../model/models";
import { ShowChildrenEvent } from "../../../model/visibility-list.models";
import {
  VisibilityParentDescription,
  VisibilityChildDescription,
} from "../../../model/visibility.mapper.models";
import { VisibilityFilter } from "../../../model/visibility.service.models";

@Injectable()
export class VisibilityStateConfigService extends StoreConfigService<VisibilityState> {
  init = {
    manualIndicationDialogStatus: ManualIndicationDialogStatus.closed,
    manualIndicationData: null,
    manualIndicationDataUpdateStatus: ApiServiceStatus.NOT_TRIGGERED,
    manualIndicationDataUpdateStatusErrorCode: 0,
    list: null,
  };
}
@Injectable()
export class VisibilityStateService extends StoreService<VisibilityState> {
  private readonly searchModel$ = new ReplaySubject<{
    filter: VisibilityFilter;
    pagination: Pagination;
  }>(1);
  private readonly isPno12Search$ = new BehaviorSubject<boolean>(false);

  private readonly vbsListData$$ = new ReplaySubject<VbsListData>(1);
  private vbsListData: VbsListData;

  private readonly putState$$ = new BehaviorSubject<PutState>({
    key: "ManualTextPno12",
    message: "",
    state: ModelStates.idle,
  });
  private putState: PutState;

  private readonly manualDialogStatus$$ =
    new BehaviorSubject<ManualIndicationDialogStatus>(1);

  constructor(
    private readonly visibilityStateConfigService: VisibilityStateConfigService
  ) {
    super(visibilityStateConfigService);
  }

  putState$(): Observable<PutState> {
    return this.putState$$.asObservable();
  }

  vbsListData$(): Observable<VbsListData> {
    return this.vbsListData$$.asObservable();
  }

  manualDialogStatus$(): Observable<ManualIndicationDialogStatus> {
    return this.manualDialogStatus$$.asObservable();
  }

  getSearchModel$() {
    return this.searchModel$.asObservable();
  }

  getIsPno12Search$() {
    return this.isPno12Search$.asObservable();
  }

  public setSearchModel(search: {
    filter: VisibilityFilter;
    pagination: Pagination;
  }) {
    this.searchModel$.next(search);
  }

  public setIsPno12Search(isPno12Search: boolean) {
    this.isPno12Search$.next(isPno12Search);
  }

  public setVbsListData(mappedData: VbsListData) {
    this.vbsListData = mappedData;
    this.vbsListData$$.next(this.vbsListData);
  }

  private setPutState(data: PutState) {
    this.putState = data;
    this.putState$$.next(this.putState);
  }

  public setParentShowChildren(showChildrenEvent: ShowChildrenEvent) {
    const inputData = { ...this.vbsListData };
    const parent = this.getParentByPno12AndModelYear(
      inputData,
      showChildrenEvent.pno12,
      showChildrenEvent.modelYear
    );
    parent.childrenHidden = !showChildrenEvent.showChildren;

    this.setVbsListData(inputData);
  }

  public setParentVisibility(
    visible: boolean,
    saving: boolean,
    pno12: string,
    modelYear: number
  ) {
    const inputData = { ...this.vbsListData };
    const parent = this.getParentByPno12AndModelYear(
      inputData,
      pno12,
      modelYear
    );
    const value = this.getParentDescriptionItem(parent);
    this.setVisibilityOnParentDescription(value, visible);
    this.setSavingOnParentDescription(value, saving);
    this.setVbsListData(inputData);
  }

  public setOptionVisibility(
    visible: boolean,
    saving: boolean,
    pno12: string,
    modelYear: number,
    optionId: string
  ) {
    const inputData = { ...this.vbsListData };
    const child = this.getChildByPno12ModelYearAndOption(
      inputData,
      pno12,
      modelYear,
      optionId
    );
    const value = this.getChildDescriptionItem(child);

    this.setVisibilityOnChildDescription(value, visible);
    this.setSavingOnChildDescription(value, saving);

    this.setVbsListData({ ...this.vbsListData });
  }

  public setLeadTimeTextState(itemState: ModelStates, message?: string) {
    const state = { ...this.putState };
    state.key = "ManualTextPno12";
    state.state = itemState;
    state.message = message;
    this.setPutState(state);
  }

  private setSavingOnParentDescription(
    value: VisibilityParentDescription,
    saving: boolean
  ) {
    value.isSavingVisibility = saving;
  }

  private setVisibilityOnParentDescription(
    value: VisibilityParentDescription,
    visible: boolean
  ) {
    value.isVisible = visible;
  }

  private setVisibilityOnChildDescription(
    value: VisibilityChildDescription,
    visible: boolean
  ) {
    value.isVisible = visible;
  }

  private setSavingOnChildDescription(
    value: VisibilityChildDescription,
    saving: boolean
  ) {
    value.isLoading = saving;
  }

  private getParentByPno12AndModelYear(
    dataState: VbsListData,
    pno12: string,
    modelYear: number
  ) {
    const sectionIndex = this.findSectionIndexByPno12AndModelYear(
      dataState,
      pno12,
      modelYear
    );
    const parentIndex = this.findParentIndexBySectionIndexPno12AndModelYear(
      dataState,
      sectionIndex,
      pno12,
      modelYear
    );

    return this.vbsListData.sections.sections[sectionIndex].parents[
      parentIndex
    ];
  }

  private getChildByPno12ModelYearAndOption(
    dataState: VbsListData,
    pno12: string,
    modelYear: number,
    optionId: string
  ) {
    const sectionIndex = this.findSectionIndexByPno12AndModelYear(
      dataState,
      pno12,
      modelYear
    );
    const parentIndex = this.findParentIndexBySectionIndexPno12AndModelYear(
      dataState,
      sectionIndex,
      pno12,
      modelYear
    );
    const childIndex =
      this.findChildindexBySectionIndexAndParentIndexAndOptionId(
        dataState,
        sectionIndex,
        parentIndex,
        optionId
      );

    return dataState.sections.sections[sectionIndex].parents[parentIndex]
      .children[childIndex];
  }

  private findSectionIndexByPno12AndModelYear(
    dataState: VbsListData,
    pno12: string,
    modelYear: number
  ) {
    return dataState.sections.sections.findIndex((section) => {
      return this.sectionHasParentWithPno12AndModelYear(
        section,
        pno12,
        modelYear
      );
    });
  }

  private findParentIndexBySectionIndexPno12AndModelYear(
    dataState: VbsListData,
    sectionIndex: number,
    pno12: string,
    modelYear: number
  ) {
    return dataState.sections.sections[sectionIndex].parents.findIndex(
      (parent) => {
        const parentDescriptionItem = this.getParentDescriptionItem(parent);
        return (
          parentDescriptionItem.pno12 === pno12 &&
          parentDescriptionItem.modelYear === modelYear
        );
      }
    );
  }

  private findChildindexBySectionIndexAndParentIndexAndOptionId(
    dataState: VbsListData,
    sectionIndex: number,
    parentIndex: number,
    optionId: string
  ) {
    return dataState.sections.sections[sectionIndex].parents[
      parentIndex
    ].children.findIndex((child) => {
      return this.getChildDescriptionItem(child).optionId === optionId;
    });
  }

  private sectionHasParentWithPno12AndModelYear(
    section: VbsListSection,
    pno12: string,
    modelYear: number
  ): unknown {
    return (
      section.parents.find((parent) => {
        const parentDeacriptionItem = this.getParentDescriptionItem(parent);
        return (
          parentDeacriptionItem.pno12 === pno12 &&
          parentDeacriptionItem.modelYear === modelYear
        );
      }) != null
    );
  }

  private getParentDescriptionItem(parent: VbsListParent) {
    return parent.values.find((value) => value.key === "description")
      ?.item as VisibilityParentDescription;
  }

  private getChildDescriptionItem(child: VbsListChild) {
    return child.values.find((value) => value.key === "description")
      ?.item as VisibilityChildDescription;
  }
}
