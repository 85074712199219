import {
  DistributionPoint,
  Partner,
} from "../../../../../core/models/models.api";

export function mapToPartner(
  distributionPoints: DistributionPoint[],
  currentDistributionPoint: string,
  partner: Partner
) {
  const newDistributionPoint: DistributionPoint = distributionPoints.find(
    (dp) => dp.code === currentDistributionPoint
  );
  const newPartner: Partner = {
    ...partner,
    currentDistributionPoint: { ...newDistributionPoint },
  };
  return newPartner;
}
