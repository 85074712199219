import { Injectable } from "@angular/core";
import { TranslationService } from "../../../core/label/translation.service";
import {
  DistributionPoint
} from "../../../core/models/models.api";

@Injectable()
export class DistributionPointsMapperService {
  constructor(private readonly translationService: TranslationService) {}

  mapAndSort(data: DistributionPoint[]): DistributionPoint[] {
    const list: DistributionPoint[] = [];

    data.forEach((distribution) => {
      list.push({
        isDefault: distribution.isDefault,
        isCurrent: distribution.isCurrent,
        code: distribution.code,
        address:
          distribution.address ??
          this.translationService.get("distributionPointAddress"),
        city:
          distribution.city ??
          this.translationService.get("distributionPointCity"),
      });
    });
    return this.sortDistributionPointByCity(list);
  }

  private sortDistributionPointByCity(item: DistributionPoint[]) {
    return item.sort((a, b) => (a.city > b.city ? 1 : -1));
  }
}
