import { Injectable, OnDestroy } from "@angular/core";
import { map, tap } from "rxjs/operators";
import { UserService } from "../../../core/auth/user.service";
import { UserContext } from "../models/models";
import { ApplicationStateService } from "../redux/state/application-state.service";

@Injectable({
  providedIn: "root",
})
export class ContextService implements OnDestroy {
  public userContext: UserContext = new UserContext();

  constructor(
    private readonly state: ApplicationStateService,
    private readonly userService: UserService
  ) {}

  private readonly setUserContext$ = this.currentMarket$()
    .pipe(tap((market) => (this.userContext = new UserContext(market.roles))))
    .subscribe();

  public currentMarket$() {
    return this.state.getCurrentMarket$();
  }

  public currentLanguage$() {
    return this.userService.languageCode$();
  }

  public marketLanguages$() {
    return this.state
      .getCurrentMarket$()
      .pipe(map((market) => market.languages));
  }

  public currentCountry$() {
    return this.state.getCurrentMarket$().pipe(map((market) => market.country));
  }

  public currentCountryCode$() {
    return this.state
      .getCurrentMarket$()
      .pipe(map((market) => market.countryCode));
  }

  public currentUserName$() {
    return this.state.getCurrentUsername$();
  }

  public currentUserEmail$() {
    return this.userService.User$().pipe(map((user) => user.email));
  }

  public currentMarketCode$() {
    return this.state
      .getCurrentMarket$()
      .pipe(map((currentMarketCode) => currentMarketCode.programMarket));
  }

  public currentMarkets$() {
    return this.state.getUserMarkets$();
  }

  ngOnDestroy(): void {
    this.setUserContext$.unsubscribe();
  }
}
