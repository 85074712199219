import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from './log.service';


@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private readonly logService: LogService) { }
  handleError(error: any): void {
    console.error(error);
    this.logService.logException(error);
  }
}
