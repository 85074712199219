import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContextService } from '../../../../application/shared/facade/context.service';
import { DistributionPointsResponse } from '../../../models/models.api';
import { SimpleServiceConfig } from '../../../models/models.api.generic';
import { APISimpleService } from '../../generic/api-simple.service';

@Injectable({
  providedIn: "root",
})
export class DistributionPointsServiceConfig extends SimpleServiceConfig<DistributionPointsResponse> {
  constructor(
    private readonly contextService: ContextService
  ) {
    super();
  }

  readUrl$ = () =>
    combineLatest([this.contextService.currentMarket$()]).pipe(
      map(([market]) => `/api/users/markets/${market.programMarket}/partner/distributionpoints`)
    );
}

@Injectable({
  providedIn: 'root'
})
export class DistributionPointsService extends APISimpleService<DistributionPointsResponse> {
  constructor(http: HttpClient, config: DistributionPointsServiceConfig) {
    super(http, config);
  }
}
