import { Injectable } from "@angular/core";
import { StoreConfigService, StoreService } from "angular-simple-redux-lib";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { SimpleServiceStatus } from "../../../../core/api/generic/api-simple.service";
import { UserMarket, UserMarkets } from "../../../../core/models/models.api";
import { ApiServiceStatus } from "../../../../core/models/models.api.generic";
import { ApplicationState, MarketInfoDialogStatus } from "../../models/models";
import { ApiStatus } from "../../models/redux/store/api-status";

@Injectable()
export class ApplicationStateConfigService extends StoreConfigService<ApplicationState> {
  init = {
    distributionPoints: null,
    partner: null,
    distributionPointStatus: ApiStatus.IDLE,
    distributionPointsStatus: SimpleServiceStatus.IDLE,
    distributionPointError: null,
    partnerStatus: SimpleServiceStatus.IDLE,
    leadtimeShutdownTexts: [],
    marketInfoDialogStatus: MarketInfoDialogStatus.closed,
    isNoticeAvailable: false,
    noticeMessages: [],
    isShutdownEnabled: false,
    shutdownMessages: [],
    updateShutdownStatus: ApiServiceStatus.NOT_TRIGGERED,
    updateNoticeStatus: ApiServiceStatus.NOT_TRIGGERED,
  };
}

@Injectable()
export class ApplicationStateService extends StoreService<ApplicationState> {
  private readonly currentUsername$ = new ReplaySubject<string>(1);
  private readonly currentMarket$ = new ReplaySubject<UserMarket>(1);
  private readonly searchDialogStatus$ = new BehaviorSubject<boolean>(false);
  private readonly settingsDialogStatus$ = new BehaviorSubject<boolean>(false);
  private readonly userMarkets$ = new ReplaySubject<UserMarkets>(1);

  constructor(
    private readonly applicationStateConfigService: ApplicationStateConfigService
  ) {
    super(applicationStateConfigService);
  }

  setUserMarkets(data: UserMarkets) {
    this.userMarkets$.next(data);
  }

  setUserName(userName: string) {
    this.currentUsername$.next(userName);
  }
  setMarket(market: UserMarket) {
    this.currentMarket$.next(market);
  }
  setDialogStatus(id: string, value: boolean) {
    if (id === "search-mobile-dialog") {
      this.searchDialogStatus$.next(value);
    } else if (id === "settings-mobile-dialog") {
      this.settingsDialogStatus$.next(value);
    }
  }

  getUserMarkets$() {
    return this.userMarkets$.asObservable();
  }
  getCurrentMarket$() {
    return this.currentMarket$.asObservable();
  }
  getCurrentUsername$() {
    return this.currentUsername$.asObservable();
  }
  getSearchDialogIsOpen$() {
    return this.searchDialogStatus$.asObservable();
  }
  getSettingsDialogIsOpen$() {
    return this.settingsDialogStatus$.asObservable();
  }
}
