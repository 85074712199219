import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { BrowserHelperService } from "../../../../../core/utils/browser-helper.service";
import { AverageLeadtimesMapperService } from "./average-leadtimes-mapper.service";
import { AverageLeadtimesStateService } from "./average-leadtimes-state.service";
import { AverageLeadtimesState } from "./models/models";

@Injectable()
export class AverageLeadtimesStateTestService extends AverageLeadtimesStateService {
  constructor(
    private readonly browserHelper: BrowserHelperService,
    private readonly mapper: AverageLeadtimesMapperService
  ) {
    super();
    console.log("LOGG: [Test] AverageLeadtimesStateTestService - constructor");
  }

  // == USECASE: show error state ====== PATH: /overview?show=error
  // == USECASE: show loding state ====== PATH: /overview?show=loading
  // == USECASE: show first model selected ====== PATH: /overview?show=selectfirstmodel

  public get$<K extends keyof AverageLeadtimesState>(key: K) {
    if (this.browserHelper.parameter("show") === "error") {
      if (key === "error") {
        return of(true) as Observable<AverageLeadtimesState[K]>;
      }
      if (key === "loading") {
        return of(false) as Observable<AverageLeadtimesState[K]>;
      }
    }

    if (this.browserHelper.parameter("show") === "loading") {
      if (key === "error") {
        return of(false) as Observable<AverageLeadtimesState[K]>;
      }
      if (key === "loading") {
        return of(true) as Observable<AverageLeadtimesState[K]>;
      }
    }

    if (this.browserHelper.parameter("show") === "selectfirstmodel") {
      if (key === "selectedModel") {
        return super.get$("models").pipe(
          filter((allModels) => !!allModels),
          map((allModels) => allModels.models.find(() => true)),
          filter((model) => !!model),
          map((model) => this.mapper.mapToSelectedModel(model))
        ) as Observable<AverageLeadtimesState[K]>;
      }
    }

    return super.get$(key);
  }
}
