import { Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { fromEvent, merge } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ViewportService implements OnDestroy {
  private readonly viewportMeta = document.getElementById("viewport-meta");

  private readonly viewports = {
    mobileOrDesktop: "width=device-width, initial-scale=0.86, maximum-scale=5.0, minimum-scale=0.86",
    tablet: "width=1104",
  };

  constructor(private readonly router: Router) {
  }

  public setViewport() {
    this.viewport_set();
  }

  private browserDimentionRation_get() {
    return window.innerWidth / window.innerHeight;
  }

  private screenDimentionRations_get() {
    const screenWidthHeightRatio = screen.width / screen.height;
    const screenHeightWidthRatio = screen.height / screen.width;
    return { screenWidthHeightRatio, screenHeightWidthRatio };
  }

  private browserVsScreenDimentionDiff_get(
    browserWidthRaition: number,
    screenWidthRatio: number,
    screenHeightRatio: number
  ) {
    const screenWidthHeightRatioDiffFromWindowInnerwidthWidthHeightRatioAsPositiveNumber = Math.abs(
      browserWidthRaition - screenWidthRatio
    );
    const screenHeightWidthRatioDiffFromWindowInnerwidthWidthHeightRatioAsPositiveNumber = Math.abs(
      browserWidthRaition - screenHeightRatio
    );

    return {
      widthHeightDiff: screenWidthHeightRatioDiffFromWindowInnerwidthWidthHeightRatioAsPositiveNumber,
      heightWidthDiff: screenHeightWidthRatioDiffFromWindowInnerwidthWidthHeightRatioAsPositiveNumber,
    };
  }

  private browserWidthRatioClosestToScreenWidthRatio_get(
    browserWidthRatioDiff: number,
    browserHeightRatioDiff: number
  ) {
    return browserHeightRatioDiff >= browserWidthRatioDiff;
  }

  private screenWidthEvenIfDeviceDontChangeWidthOnLandscape_get(
    useWidthAsWidth: boolean
  ) {
    return useWidthAsWidth ? screen.width : screen.height;
  }

  private shouldScaleDownApplication_get(realWidth: number) {
    const isOnLeadtimes = location.pathname.startsWith("/leadtimes");
    const scaleDownRoutesThatIsNotMobileAdapted =
      realWidth < 1024 && !isOnLeadtimes;
    const scaleDownRoutesThatIsMobileAdapted =
      realWidth < 1024 && realWidth > 600;

    return (
      scaleDownRoutesThatIsMobileAdapted ||
      scaleDownRoutesThatIsNotMobileAdapted
    );
  }

  private realScreenWidthEvenIfLandscape_get() {
    const browserDimentionRatio = this.browserDimentionRation_get();

    const screenDimentionRations = this.screenDimentionRations_get();

    const getScreenAndBrowserDiff = this.browserVsScreenDimentionDiff_get(
      browserDimentionRatio,
      screenDimentionRations.screenWidthHeightRatio,
      screenDimentionRations.screenHeightWidthRatio
    );

    const useWidthAsWidth = this.browserWidthRatioClosestToScreenWidthRatio_get(
      getScreenAndBrowserDiff.widthHeightDiff,
      getScreenAndBrowserDiff.heightWidthDiff
    );

    return this.screenWidthEvenIfDeviceDontChangeWidthOnLandscape_get(
      useWidthAsWidth
    );
  }

  private viewport_set() {
    setTimeout(() => {
      const realWidth = this.realScreenWidthEvenIfLandscape_get();
      const shouldScaleDownApplication = this.shouldScaleDownApplication_get(
        realWidth
      );
      if (this.viewportMeta) {
        if (!environment.production) {
          console.log("LOGG: VIEWPORT SCALE DOWN", shouldScaleDownApplication);
        }
        if (shouldScaleDownApplication) {
          this.viewportMeta.setAttribute(
            "content",
            this.viewports.tablet
          );
        } else {
          this.viewportMeta.setAttribute(
            "content",
            this.viewports.mobileOrDesktop
          );
        }
      }
    }, 1000);
  }

  private readonly windowResize$ = fromEvent(window, "resize").pipe(
    tap(() => {
      this.viewport_set();
    })
  );

  private readonly navigation$ = this.router.events.pipe(
    filter((value) => value instanceof NavigationEnd),
    tap(() => {
      this.viewport_set();
    })
  );

  private readonly subscriptions = merge(
    this.navigation$,
    this.windowResize$
  ).subscribe();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
