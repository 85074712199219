import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { APISimpleService } from "./../generic/api-simple.service";
import { UserMarkets } from "../../models/models.api";
import { SimpleServiceConfig } from "../../models/models.api.generic";

@Injectable({
  providedIn: "root",
})
export class UserMarketsServiceConfig extends SimpleServiceConfig<UserMarkets> {
  constructor() {
    super();
  }
  readUrl$ = () => of(`/api/users/markets`);
}

@Injectable({
  providedIn: "root",
})
export class UserMarketsService extends APISimpleService<UserMarkets> {
  constructor(http: HttpClient, config: UserMarketsServiceConfig) {
    super(http, config);
  }
}
