import { Injectable } from "@angular/core";
import { DateRangeService } from "./date-range.service";

@Injectable({
  providedIn: "root",
})
export class DateRangeYearDecoratorService {
  monthFormat: string;
  yearFormat: string;
  endDate: Date;

  constructor(private readonly dateRange: DateRangeService) {}

  public addMonthFormat(format: string) {
    this.monthFormat = format;
    return this;
  }

  public addYearFormat(format: string) {
    this.yearFormat = format;
    return this;
  }

  public addStartDate(date: Date) {
    this.dateRange.addStartDate(date);
    return this;
  }

  public addEndDate(date: Date) {
    this.endDate = date;
    this.dateRange.addEndDate(date);
    return this;
  }

  public build() {
    const moreThanAYear = this.endDateIsMoreThatAYear();
    if (moreThanAYear) {
      this.dateRange.addFormat(this.yearFormat);
    } else {
      this.dateRange.addFormat(this.monthFormat);
    }

    return this.dateRange.build();
  }

  public endDateIsMoreThatAYear() {
    const today = new Date();
    const futureDate = new Date(this.endDate);

    const differenceInTime = futureDate.getTime() - today.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays > 365;
  }
}
