// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ApplicationStateTestService } from "../app/application/shared/redux/state/application-state-test.service";
import { ApplicationStateService } from "../app/application/shared/redux/state/application-state.service";
import { LeadtimesStateTestService } from "../app/leadtime/shared/state/leadtimes-state-test.service";
import { LeadtimesStateService } from "../app/leadtime/shared/state/leadtimes-state.service";
import { AverageLeadtimesStateTestService } from "../app/overview/pages/widgets/average-leadtimes-widget/shared/average-leadtimes-state-test.service";
import { AverageLeadtimesStateService } from "../app/overview/pages/widgets/average-leadtimes-widget/shared/average-leadtimes-state.service";
import { VisibilityStateTestService } from "../app/visibility/shared/services/redux/state/test/visibility-state-test.service";
import { VisibilityStateService } from "../app/visibility/shared/services/redux/state/visibility-state.service";

export const environment = {
  production: false,
  modules: {
    app: [
      {
        provide: ApplicationStateService,
        useClass: ApplicationStateTestService,
      },
    ],
    averageLeadtimes: [
      {
        provide: AverageLeadtimesStateService,
        useClass: AverageLeadtimesStateTestService,
      },
    ],
    leadtime: [
      {
        provide: LeadtimesStateService,
        useClass: LeadtimesStateTestService,
      },
    ],
    visibility: [
      {
        provide: VisibilityStateService,
        useClass: VisibilityStateTestService,
      },
    ],
  },
  settings: {
    monthDateRangeFormat: "dd LLL - dd LLL",
    yearDateRangeFormat: "MMM y - MMM y",
    environment: "test",
    filterRefreshRate: 1200,
    maxVBSPerPage: 50,
    appInsights: {
      config: {
        instrumentationKey: "46bbabee-9622-4459-8929-38e78ec2f19b",
      },
    },
    authConfig: {
      issuer: "https://lit-test.volvocars.biz/identity",
      loginUrl: "https://lit-test.volvocars.biz/identity/connect/authorize",
      logoutUrl: "https://lit-test.volvocars.biz/identity/connect/endsession",
      tokenEndpoint: "https://lit-test.volvocars.biz/identity/connect/token",
      sessionCheckIFrameUrl:
        "https://lit-test.volvocars.biz/identity/connect/checksession",
      userinfoEndpoint:
        "https://lit-test.volvocars.biz/identity/connect/userinfo",
      redirectUri: window.location.origin,
      clientId: "lit",
      responseType: "code",
      scope: "openid profile email offline_access api",
      showDebugInformation: true,
    },
    allowedUrls: ["/api", "http://localhost", "https://lit-test.volvocars.biz"],
    signalR: {
      showDebugInformation: true,
    },
  },
  loadDynamicValues: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
