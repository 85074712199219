import { DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  ApplicationRef,
  DoBootstrap,
  ErrorHandler,
  NgModule,
} from "@angular/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OAuthModule } from "angular-oauth2-oidc";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { DialogIeWarningComponent } from "./application/components/dialog-ie-warning/dialog-ie-warning.component";
import { DialogMarketInformationComponent } from "./application/components/dialog-market-information/dialog-market-information.component";
import { ApplicationFacadeService } from "./application/shared/facade/application-facade.service";
import { DistributionPointsMapperService } from "./application/shared/mapper/distribution-points-mapper.service";
import { BootstrapService } from "./core/bootstrap/bootstrap.service";
import { NgxFeatureToggleGuardForSlotsService } from "./core/guard/NgxFeatureToggleGuardForSlotsService";
import { NscAdminAuthGuardService } from "./core/guard/nscAdminAuthGuard.service";
import { RetailerAuthGuardService } from "./core/guard/retailerAuthGuard.service";
import { NoCacheInterceptor } from "./core/interceptors/no-cache.interceptor";
import { GlobalErrorHandlerService } from "./core/logging/global-error-handler.service";
import { PlaceholderComponent } from "./placeholder.component";
import { PlaceholderErrorComponent } from "./placeholder.error.component";
import { FlexModule } from "./ui/flex/flex.module";
import { MenusModule } from "./ui/menus/menus.module";
import { PendingChangesDialogComponent } from "./application/components/pending-changes-dialog/pending-changes-dialog.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ApplicationStateConfigService } from "./application/shared/redux/state/application-state.service";

@NgModule({
  declarations: [
    PlaceholderComponent,
    DialogMarketInformationComponent,
    DialogIeWarningComponent,
    PendingChangesDialogComponent,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    RetailerAuthGuardService,
    NscAdminAuthGuardService,
    NgxFeatureToggleGuardForSlotsService,
    DistributionPointsMapperService,
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
    ApplicationFacadeService,
    ApplicationStateConfigService,
    DatePipe,
    ...environment.modules.app,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MenusModule,
    FlexModule,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: environment.settings.allowedUrls,
        sendAccessToken: true,
      },
    }),
    MatMomentDateModule,
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private readonly bootstrapService: BootstrapService) {}

  ngDoBootstrap(appRef: ApplicationRef) {
    this.bootstrapService
      .bootstrap()
      .then(() => appRef.bootstrap(PlaceholderComponent))
      .catch(() => appRef.bootstrap(PlaceholderErrorComponent));
  }
}
