import { KeyValue } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LogService } from "../logging/log.service";
import { ErrorService } from "./../error/error.service";

@Directive()
@Injectable({
  providedIn: "root",
})
export class TranslationService {
  private readonly localePath = "/api/translations";

  private readonly languageCodeSource$$ = new BehaviorSubject<string>("");
  public languageCode$ = this.languageCodeSource$$.asObservable();

  private readonly languageLabelsSource$$ = new BehaviorSubject({});
  public languageLabels$ = this.languageLabelsSource$$.asObservable();

  @Output() languageSet: EventEmitter<string> = new EventEmitter();

  constructor(
    private readonly logService: LogService,
    private readonly errorService: ErrorService,
    private readonly httpService: HttpClient
  ) {}

  private getLanguageContent(
    marketCode: string,
    languageCode: string
  ): Promise<any> {
    const url = `${this.localePath}/${marketCode}/${languageCode}`;

    return new Promise<any>((resolve, reject) => {
      this.httpService.get(url).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  public setLanguage(marketCode: string, languageCode: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getLanguageContent(marketCode, languageCode)
        .then((langFile) => {
          this.setAndEmitLanguageContent(langFile, languageCode);
          resolve();
        })
        .catch((_) => {
          this.logService.logError(
            `Could not load language file for market ${marketCode} and language ${languageCode}.`
          );
          this.errorService.updateError(true);
          reject(_);
        });
    });
  }

  private setAndEmitLanguageContent(content: any, languageCode: string) {
    // set the labels property
    this.logService.logTrace(
      `The language file ${languageCode} has been loaded.`
    );

    // notify any subscribers
    this.languageSet.emit(languageCode);

    this.languageCodeSource$$.next(languageCode);
    this.languageLabelsSource$$.next(content);
  }

  public isValidLanguageCode(languageCode: string): boolean {
    const pattern = "^([a-zA-Z]{2})+$";
    const match = languageCode.match(pattern);
    if (match && match.length > 0) {
      return true;
    }
    return false;
  }

  public get(label: string): string {
    // the substitute output is returned if the label cannot be found
    const substituteOutput = `{{${label}}}`;
    const languageList = this.languageLabelsSource$$.value;

    if (languageList[label]) {
      return languageList[label];
    } else {
      // remember it/add to collection of labels
      // to avoid further logging for other places the label is used
      languageList[label] = substituteOutput;
      this.logService.logError(
        `Could not find translation for label: ${label}`
      );

      return substituteOutput;
    }
  }

  public getWithFormat(label: string, ...val: any[]): string {
    let retVal = this.get(label);
    for (let index = 0; index < val.length; index++) {
      retVal = retVal.replace(`{${index}}`, val[index]);
    }

    return retVal;
  }

  public getLabelCount() {
    return Object.keys(this.languageLabelsSource$$.value).length;
  }

  public saveTranslations(
    market: string,
    languageCode: string,
    translations: KeyValue<string, string>[]
  ) {
    return this.httpService.put(
      `/api/translations/${market}/${languageCode}`,
      translations
    );
  }
}
