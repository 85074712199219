import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { FilterContainer, PageSearchStateModel, SearchModel } from '../models/models';

@Injectable()
export class SearchBarStateService {

  private readonly _selectedFilters$ = new ReplaySubject<SearchModel>(1);
  private readonly _filters$ = new ReplaySubject<FilterContainer>(1);
  private readonly _isSearching$ = new ReplaySubject<boolean>(1);
  private readonly _paginationInfo$ = new ReplaySubject<PageSearchStateModel>(1);

  constructor() { }

  paginationInfo$() {
    return this._paginationInfo$.asObservable();
  }
  paginationInfo(model: PageSearchStateModel) {
    this._paginationInfo$.next(model);
  }
  isSearching$() {
    return this._isSearching$.asObservable();
  }
  isSearching(model: boolean) {
    this._isSearching$.next(model);
  }
  filters$() {
    return this._filters$.asObservable();
  }
  filters(model: FilterContainer) {
    this._filters$.next(model);
  }
  selectedFilters$() {
    return this._selectedFilters$.asObservable();
  }
  selectedFilters(model: SearchModel) {
    this._selectedFilters$.next(model);
  }


}
