import { Injectable } from "@angular/core";
import { DateRangeYearDecoratorService } from "./date-range-year-decorator.service";

@Injectable({
  providedIn: "root",
})
export class DateRangeYearRemoveStartDateForSameMonthDecoratorService {
  constructor(
    private readonly dateRangeBuilderFormatDecoratorService: DateRangeYearDecoratorService
  ) {}

  public addMonthFormat(format: string) {
    this.dateRangeBuilderFormatDecoratorService.addMonthFormat(format);
    return this;
  }

  public addYearFormat(format: string) {
    this.dateRangeBuilderFormatDecoratorService.addYearFormat(format);
    return this;
  }

  public addStartDate(date: Date) {
    this.dateRangeBuilderFormatDecoratorService.addStartDate(date);
    return this;
  }

  public addEndDate(date: Date) {
    this.dateRangeBuilderFormatDecoratorService.addEndDate(date);
    return this;
  }

  public build() {
    const result = this.dateRangeBuilderFormatDecoratorService.build();

    const moreThanAYear =
      this.dateRangeBuilderFormatDecoratorService.endDateIsMoreThatAYear();
    const seperatedStrings = result.split("-");
    const startText =
      seperatedStrings.length > 0 ? seperatedStrings[0].trim() : "";
    const endText =
      seperatedStrings.length > 1 ? seperatedStrings[1].trim() : "";
    const sameStartAndEndText = startText === endText;

    if (moreThanAYear && sameStartAndEndText) {
      return endText;
    }

    return result;
  }
}
