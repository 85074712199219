import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { LeadtimesStateConfigService } from "./leadtimes-state-config.service";
import { LeadtimesStateService } from "./leadtimes-state.service";

@Injectable()
export class LeadtimesStateTestService extends LeadtimesStateService {

  constructor(
    private readonly superConfig: LeadtimesStateConfigService
  ) {
    super(superConfig);
    console.log("LOGG: [Test] LeadtimesStateTestService - constructor");
  }

  // == USECASE: Show shutdown alert ====== PATH: /leadtimes?q=showshutdownalert
  public getShowShutdownWarning$() {
    if (this.getQQuerystringValue() === "q=showshutdownalert") {
      return this._getShowShutdownWarning$();
    }

    return super.getShowShutdownWarning$();
  }

  private _getShowShutdownWarning$() {
    return super.getShowShutdownWarning$().pipe(
      map(() => true)
    );
  }


  private getQQuerystringValue() {
    const queryStringInUri = location.search;
    const queryStrings = queryStringInUri.split(/\?|&/);
    return queryStrings.find((qs) => qs.startsWith("q="));
  }

}
