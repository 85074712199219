import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { RouterModule } from "@angular/router";
import { FlexModule } from "../flex/flex.module";
import { FormControlsModule } from "../form-controls/form-controls.module";
import { IndicatorsModule } from "../indicators/indicators.module";
import { PaginatorComponent } from "./paginator/paginator.component";
import { SearchBarDesktopComponent } from './search-bar/search-bar-desktop/search-bar-desktop.component';
import { SearchBarMobileComponent } from './search-bar/search-bar-mobile/search-bar-mobile.component';
import { SearchBarStateService } from "./search-bar/shared/facade/search-bar-state.service";
import { SearchService } from "./search-bar/shared/mediator/search.service";
import { SearchToolboxComponent } from "./search-toolbox/search-toolbox.component";
import { ShutdownDialogComponent } from './shutdown-dialog/shutdown-dialog.component';
import { SlotFilterToolbarComponent } from "./slot-filter-toolbar/slot-filter-toolbar.component";
import { TableToolbarComponent } from "./table-toolbar/table-toolbar.component";
import { NoticeDialogComponent } from './notice-dialog/notice-dialog/notice-dialog.component';
import { BadgeButtonComponent } from "../indicators/badge-button/badge-button.component";

@NgModule({
  declarations: [
    SearchBarMobileComponent,
    SearchBarDesktopComponent,
    PaginatorComponent,
    TableToolbarComponent,
    SearchToolboxComponent,
    SlotFilterToolbarComponent,
    ShutdownDialogComponent,
    NoticeDialogComponent,
    BadgeButtonComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule.withConfig({ disableDefaultBps: true }),
    FormControlsModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule,
    FlexModule,
    IndicatorsModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
  exports: [
    SearchBarMobileComponent,
    SearchBarDesktopComponent,
    PaginatorComponent,
    TableToolbarComponent,
    SearchToolboxComponent,
  ],
})
export class MenusModule {
  static withSearchProviders(): ModuleWithProviders<MenusModule> {
    return {
      ngModule: MenusModule,
      providers: [SearchService, SearchBarStateService],
    };
  }
}
