import { Injectable } from "@angular/core";
import { detect } from "detect-browser";

interface BrowserInfo {
  name: string;
  version: string;
  os: string;
  type: string;
}

@Injectable({
  providedIn: "root",
})
export class BrowserAgentService {
  public browserInfo: BrowserInfo;

  constructor() {
    this.init();
  }

  private init(): void {
    this.browserInfo = detect();
  }

  public get IsIE11(): boolean {
    return (
      this.browserInfo.name === "ie" &&
      this.browserInfo.version.startsWith("11.")
    );
  }

  public get isMobileDevice(): boolean {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
