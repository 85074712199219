import { Component, Inject, OnInit } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";
import { MessageType } from "../model";

@Component({
  selector: "app-snackbarmessage",
  templateUrl: "./snackbarmessage.component.html",
  styleUrls: ["./snackbarmessage.component.scss"],
})
export class SnackbarmessageComponent implements OnInit {
  constructor(
    private readonly snackBarRef: MatSnackBarRef<SnackbarmessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}
  MessageType = MessageType;
  public iconName: string;

  ngOnInit() {
    this.iconName =
      this.data?.messageType === MessageType.error
        ? "warning"
        : this.data?.messageType === MessageType.success
        ? "success"
        : "help";
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
