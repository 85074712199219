import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { FeaturetoggleService } from "../api/feature-toggle/featuretoggle.service";

@Injectable({
  providedIn: "root",
})
export class OverviewFeaturetoggleGuardService implements CanActivate {
  constructor(
    private readonly featuretoggleService: FeaturetoggleService,
    private readonly route: Router
  ) {}

  canActivate() {
    if (!this.featuretoggleService.isActive("OverviewModule")) {
      return this.route.parseUrl("/");
    }
    return true;
  }
}
