import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { APIBodyServiceConfig } from "../../models/models.api.generic";

import { Notification } from "../../models/signalR/notification";


@Injectable({
  providedIn: 'root'
})
export class UpdateShutdownBodyConfigService extends APIBodyServiceConfig<
Notification
> {
  constructor() {
    super();
  }

  body$ = (item: Notification) => {
    return of(item);
  };
}
