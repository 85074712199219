import { Injectable, OnDestroy } from "@angular/core";
import { lastValueFrom, take } from "rxjs";
import { UserMarketsService } from "../../../core/api/market/user-markets.service";
import { UserService } from "../../../core/auth/user.service";
import { LocaleService } from "../../../core/locale/locale.service";
import { UserMarket } from "../../../core/models/models.api";
import { BrowserHelperService } from "../../../core/utils/browser-helper.service";
import { SubSink } from "subsink";
import { MarketStatusFacadeService } from "./market-status-facade.service";
import { ApplicationStateService } from "../redux/state/application-state.service";

@Injectable({
  providedIn: "root",
})
export class MarketFacadeService implements OnDestroy {
  private readonly subSink = new SubSink();
  constructor(
    private readonly state: ApplicationStateService,
    private readonly userMarketsService: UserMarketsService,
    private readonly browserHelper: BrowserHelperService,
    private readonly marketStatusFacade: MarketStatusFacadeService,
    private readonly userService: UserService,
    private readonly localeService: LocaleService
  ) {
    this.setupMarket();
  }

  public async setCurrentMarket() {
    this.setPreferredMarket();
    const market = await this.getMarketToLoad();
    this.bootstrapMarket(market);
  }

  private setupMarket() {
    this.subSink.sink = this.userMarketsService.data$().subscribe((data) => {
      this.state.setUserMarkets(data);
    });
  }

  private setPreferredMarket() {
    const loadMarket = this.browserHelper.parameter("loadMarket");
    if (loadMarket) {
      localStorage.setItem("loadMarket", loadMarket);
    }
  }

  private async getMarketToLoad() {
    const preferredMarket = localStorage.getItem("loadMarket");
    const markets = await lastValueFrom(
      this.state.getUserMarkets$().pipe(take(1))
    );
    return (
      markets.markets.find(
        (market) => market.programMarket === preferredMarket
      ) ?? markets.markets[0]
    );
  }

  private bootstrapMarket(market: UserMarket) {
    this.state.setMarket(market);
    this.userService.setLanguage(market.defaultLanguageCode);
    this.userService.setLastActive();
    this.localeService.setCountry(market.countryCode);
    this.localeService.registerCulture(market.defaultLanguageCode);
  }

  public status() {
    return this.marketStatusFacade;
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
