import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  APIBodyServiceConfig,
  APIServiceConfig,
} from "../../../models/models.api.generic";
import { ApiService } from "./api.service";

@Injectable()
export class UpdateApiService<RQ, RS = unknown> extends ApiService<RQ, RS> {
  constructor(
    protected readonly http: HttpClient,
    protected readonly config: APIServiceConfig<RQ>,
    protected readonly body: APIBodyServiceConfig<RQ>
  ) {
    super(http, config, body);
  }

  protected httpRequest$(url: string, body: any): Observable<RS> {
    return this.http.put<RS>(url, body);
  }
}
