import { Injectable } from "@angular/core";
import { CanLoad } from "@angular/router";
import { ContextService } from "../../application/shared/facade/context.service";

@Injectable({
  providedIn: "root",
})
export class RetailerAuthGuardService implements CanLoad {
  constructor(private readonly contextService : ContextService ) {
  }

  canLoad(): boolean{
    return this.contextService.userContext.hasRetailerRole || this.contextService.userContext.hasNscAdminRole || this.contextService.userContext.hasNscRole;
  }
}
