import { Injectable } from "@angular/core";
import { EffectOrReducer } from "angular-simple-redux-lib";
import { tap } from "rxjs";
import { Events, LogService } from "../../../../core/logging/log.service";
import { ApplicationActions } from "../../models/redux/actions/application-actions";
import { ChangeDistributionPointPayload } from "../../models/redux/actions/change-distribution-point-payload";
import { ApplicationActionsService } from "../actions/application-actions.service";

@Injectable({
  providedIn: "root",
})
export class UpdateDistributionPointLogSucceededEffectService extends EffectOrReducer {
  constructor(
    private readonly action: ApplicationActionsService,
    private readonly logService: LogService
  ) {
    super();
    this.init();
  }

  protected actionObservable$() {
    return this.action
      .on<ChangeDistributionPointPayload>(
        ApplicationActions.UPDATE_DISTRIBUTION_POINT_SUCCEDED
      )
      .pipe(
        tap((payload) => {
          this.logService.logEvent(Events.SET_DISTRIBUTIONPOINT, {
            Succeeded: true,
            DistributionPoint: payload.newValue,
          });
        })
      );
  }
}
