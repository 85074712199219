import { Injectable, OnDestroy } from "@angular/core";
import { LocaleService } from "../locale/locale.service";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";
import { TranslationService } from "../label/translation.service";

@Injectable({
  providedIn: "root",
})
export class DateRangeService implements OnDestroy {
  private format: string;
  private startDate: Date;
  private endDate: Date;
  private result: string;
  private datePipe: DatePipe;
  private readonly localeSubscription: Subscription;
  public moreThanAYear: boolean;

  constructor(
    public readonly labelService: TranslationService,
    public readonly localeService: LocaleService
  ) {
    this.localeSubscription = this.localeService.localeState$.subscribe(
      (locale) => {
        this.datePipe = new DatePipe(locale);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.localeSubscription) {
      this.localeSubscription.unsubscribe();
    }
  }

  public addFormat(format: string) {
    this.format = format;
    return this;
  }

  public addStartDate(date: Date) {
    this.startDate = date;
    return this;
  }

  public addEndDate(date: Date) {
    this.endDate = date;
    return this;
  }

  public build() {
    if (!this.startDate) {
      throw Error("Please set startDate");
    }

    if (!this.endDate) {
      throw Error("Please set endDate");
    }

    if (!this.format) {
      throw Error("Please set format");
    }

    this.result = this.format;
    this.translateText();
    this.formatDate();
    this.startDate = undefined;
    this.endDate = undefined;
    this.format = undefined;
    return this.result;
  }

  private translateText() {
    const res = this.result.match(/(\{.*?\})/g);
    if (res) {
      res.forEach((r) => {
        const label = r.replace("{", "").replace("}", "");
        this.result = this.result.replace(
          r,
          `'${this.labelService.get(label)}'`
        );
      });
    }
  }

  private formatDate() {
    const startFormat = this.result.split("-")[0];
    const endFormat = this.result.split("-")[1];
    const start = this.datePipe.transform(this.startDate, startFormat);
    const end = this.datePipe.transform(this.endDate, endFormat);
    this.result = `${start}-${end}`;
  }
}
