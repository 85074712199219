import { Injectable, OnDestroy } from "@angular/core";
import {
  distinctUntilChanged,
  map,
  merge,
  Subject,
} from "rxjs";
import { Events } from "../../models/signalR/events";
import { Notification } from "../../models/signalR/notification";
import { SignalRMediatorService } from "../signal-r/signal-r-mediator.service";

@Injectable({
  providedIn: "root",
})
export class NoticeClientService implements OnDestroy {
  private readonly noticeSubject$$: Subject<Notification> =
    new Subject<Notification>();

  constructor(
    private readonly signalRMediatorService: SignalRMediatorService
  ) {}

  private readonly actionNotice$ = this.signalRMediatorService.on<Notification>(
    Events.Notice,
    (value) => this.noticeInfo(value)
  );

  private noticeInfo(noticeInfo: Notification) {
    this.noticeSubject$$.next(noticeInfo);
  }

  public isEnabled$() {
    return this.noticeSubject$$.pipe(
      map((notice) => notice.isEnabled),
      distinctUntilChanged()
    );
  }

  public messages$() {
    return this.noticeSubject$$.pipe(
      map((notice) => notice.messages),
      distinctUntilChanged((value1, value2) => {
        return JSON.stringify(value1) === JSON.stringify(value2);
      })
    );
  }

  private readonly subscriptions = merge(this.actionNotice$).subscribe();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
