import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { FilterContainer } from "../../../ui/menus/search-bar/shared/models/models";

@Injectable({
  providedIn: "root",
})
export class FiltersService {
  private readonly filters$$: ReplaySubject<FilterContainer> =
    new ReplaySubject<FilterContainer>(1);
  private readonly error$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public error$ = this.error$$.asObservable();

  constructor(private readonly http: HttpClient) {}

  public updateFilters(market: string, languageCode: string) {
    const url = this.buildUrl(market, languageCode);

    this.http.get<FilterContainer>(url).subscribe({
      next: (x) => {
        if (x instanceof Error) {
          this.error$$.next(true);
        } else {
          this.error$$.next(false);
          this.filters$$.next(x);
        }
      },
      error: () => {
        this.error$$.next(true);
      },
    });
  }

  public filters$() {
    return this.filters$$.asObservable();
  }

  private buildUrl(market: string, languageCode: string): string {
    return `/api/search/markets/${market}/filters?language=${languageCode}`;
  }
}
