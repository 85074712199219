import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { VccInputComponent } from "./vcc-input/vcc-input.component";
import { VccMultiSelectComponent } from "./vcc-multi-select/vcc-multi-select.component";
import { VccSelectComponent } from "./vcc-select/vcc-select.component";
import { VccSlideSwitchComponent } from "./vcc-slide-switch/vcc-slide-switch.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { VccMatTextareaComponent } from "./vcc-mat-textarea/vcc-mat-textarea.component";

@NgModule({
  declarations: [
    VccSelectComponent,
    VccInputComponent,
    VccMultiSelectComponent,
    VccSlideSwitchComponent,
    VccMatTextareaComponent,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatInputModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  exports: [
    VccSelectComponent,
    VccInputComponent,
    VccMultiSelectComponent,
    VccSlideSwitchComponent,
    VccMatTextareaComponent,
  ],
})
export class FormControlsModule {}
