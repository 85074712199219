import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FactoriesComponent } from './factories/factories.component';
import { FlipSwitchComponent } from './flip-switch/flip-switch.component';
import { LocaltimeIndicatorComponent } from './localtime-indicator/localtime-indicator.component';
import { SlideSwitchComponent } from './slide-switch/slide-switch.component';

@NgModule({
  declarations: [FlipSwitchComponent, FactoriesComponent, LocaltimeIndicatorComponent, SlideSwitchComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule
  ],
  exports: [
    FlipSwitchComponent,
    FactoriesComponent,
    LocaltimeIndicatorComponent,
    SlideSwitchComponent

  ]
})
export class IndicatorsModule { }
