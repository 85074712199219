import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContextService } from '../../../application/shared/facade/context.service';
import { Partner } from '../../models/models.api';
import { SimpleServiceConfig } from '../../models/models.api.generic';


@Injectable({
  providedIn: "root",
})
export class PartnerServiceConfigService extends SimpleServiceConfig<Partner> {
  constructor(
    private readonly contextService: ContextService
  ) {
    super();
  }

  readUrl$ = () =>
    combineLatest([this.contextService.currentMarket$()]).pipe(
      map(([market]) => `/api/users/markets/${market.programMarket}/partner`)
    );
}
