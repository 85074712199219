import { Injectable, OnDestroy } from "@angular/core";
import { merge } from "rxjs";
import { PartnerService } from "../../../core/api/partner/partner.service";
import { ApplicationStateService } from "../redux/state/application-state.service";

@Injectable({
  providedIn: "root",
})
export class PartnerFacadeService implements OnDestroy {
  constructor(
    private readonly state: ApplicationStateService,
    private readonly partnerService: PartnerService
  ) {}

  public loadPartner() {
    this.partnerService.read();
  }

  public statePartner$() {
    return this.state.get$("partner");
  }

  public statePartnerStatus$() {
    return this.state.get$("partnerStatus");
  }

  private readonly triggerUpdatePartner$ = this.partnerService.data$((value) =>
    this.state.update("partner", value)
  );

  private readonly triggerPartnerStatus$ = this.partnerService.status$(
    (status) => this.state.update("partnerStatus", status)
  );

  // SUBSCRIPTIONS

  private readonly subscription = merge(
    this.triggerUpdatePartner$,
    this.triggerPartnerStatus$
  ).subscribe();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
