import { Injectable, OnDestroy } from "@angular/core";
import { merge, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { Events } from "../../models/signalR/events";
import { Notification } from "../../models/signalR/notification";
import { FeaturetoggleService } from "../feature-toggle/featuretoggle.service";
import {
  SignalRMediatorService
} from "../signal-r/signal-r-mediator.service";

@Injectable({
  providedIn: "root",
})
export class LeadtimeShutdownClientService implements OnDestroy {
  private readonly shutdownSubject$$: Subject<Notification> =
    new Subject<Notification>();

  constructor(
    private readonly signalRMediatorService: SignalRMediatorService,
    private readonly featuretoggleService: FeaturetoggleService
  ) {}

  private readonly actionShutdown$ =
    this.signalRMediatorService.on<Notification>(
      Events.Shutdown,
      (value) => this.leadtimeShutdownInfo(value)
    );

  private leadtimeShutdownInfo(shutDownInfo: Notification) {
    this.shutdownSubject$$.next(shutDownInfo);
  }

  public isEnabled$() {
    return this.shutdownSubject$$.pipe(
      filter(
        (shutDownInfo) =>
          this.featuretoggleService.isActive("LeadtimeShutdown") ||
          !shutDownInfo.isEnabled
      ),
      map((shutDownInfo) => shutDownInfo.isEnabled),
      distinctUntilChanged()
    );
  }

  public shutDownTexts$() {
    return this.shutdownSubject$$.pipe(
      map((shutDownInfo) => shutDownInfo.messages),
      distinctUntilChanged((value1, value2) => {
        return JSON.stringify(value1) === JSON.stringify(value2);
      })
    );
  }

  public shutdownMessageRecieved$() {
    return this.shutdownSubject$$.pipe(
      startWith(false),
      map(() => true)
    );
  }

  private readonly subscriptions = merge(this.actionShutdown$).subscribe();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
