import { ApiServiceStatus } from "../../../core/models/models.api.generic";
import { VbsFlatList } from "./redux/state/vbs.flat.list";
import { ListItem } from "./redux/state/vbsFlatList/common/list.item";
import { ModelManualIndicationItem } from "./redux/state/vbsFlatList/items/model/model.manual.indication";


/** LIST MODELS  **/
export interface PutState {
  state: ModelStates;
  key: string;
  message: string;
}

export enum ModelStates {
  "idle",
  "inprogress",
  "succeded",
  "error",
}

export interface ManualText {
  languageCode: string;
  language: string;
  message: string;
}


export enum ManualIndicationDialogStatus {
  "open",
  "closed"
}

export interface VisibilityState {
  manualIndicationDialogStatus: ManualIndicationDialogStatus,
  manualIndicationData: ListItem<ModelManualIndicationItem>,
  manualIndicationDataUpdateStatus: ApiServiceStatus,
  manualIndicationDataUpdateStatusErrorCode: number,
  list: VbsFlatList
}

