import { registerLocaleData } from "@angular/common";
import localeGerman from "@angular/common/locales/de";
import localeAustriaGerman from "@angular/common/locales/de-AT";
import localeSwissGerman from "@angular/common/locales/de-CH";
import localeEnglish from "@angular/common/locales/en-GB";
import localeSpain from "@angular/common/locales/es";
import localeSwissFrench from "@angular/common/locales/fr-CH";
import localeSwissItalian from "@angular/common/locales/it-CH";
import localeSwedish from "@angular/common/locales/sv";
import localeNorwegian from "@angular/common/locales/nb";
import localeDanish from "@angular/common/locales/da";
import localeFinnish from "@angular/common/locales/fi";
import localeNetherlandish from "@angular/common/locales/nl";
import localeBelgiumNetherlandish from "@angular/common/locales/nl-BE";
import localeBelgiumFrench from "@angular/common/locales/fr-BE";
import localeFrench from "@angular/common/locales/fr";
import localeGreece from "@angular/common/locales/el";
import localeIrelandEnglish from "@angular/common/locales/en-IE";
import localeItalian from "@angular/common/locales/it";
import localeHungarian from "@angular/common/locales/hu";
import localeCzech from "@angular/common/locales/cs";
import localeRussian from "@angular/common/locales/ru";
import localePolish from "@angular/common/locales/pl";
import localeUSEnglish from "@angular/common/locales/en-US-POSIX";
import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { DateAdapter } from "@angular/material/core";

@Injectable({providedIn: "root"})
export class LocaleService {
  private readonly localeStore$$ = new ReplaySubject<string>(1);
  public localeState$ = this.localeStore$$.asObservable();
  private _language = "en";
  private country = "GB";

  constructor(private readonly dateAdapter: DateAdapter<any>) {}

  private get locale() {
    return `${this.language}-${this.country}`;
  }

  private set language(value: string) {
    this._language = value;
  }

  private get language(): string {
    return this._language;
  }

  public setCountry(country: string) {
    this.country = country;
  }

  registerCulture(language: string) {
    this.language = language;

    // Register locale data since only the en-US locale data comes with Angular
    // Will always by default register en-GB locale data as a fallback if no other case matches
    switch (this.locale) {
      case "de-AT": {
        this.dateAdapter.setLocale("de-AT");
        registerLocaleData(localeAustriaGerman, this.locale);
        break;
      }
      case "it-CH": {
        this.dateAdapter.setLocale("it-CH");
        registerLocaleData(localeSwissItalian, this.locale);
        break;
      }
      case "fr-CH": {
        this.dateAdapter.setLocale("fr-CH");
        registerLocaleData(localeSwissFrench, this.locale);
        break;
      }
      case "de-CH": {
        this.dateAdapter.setLocale("de-CH");
        registerLocaleData(localeSwissGerman, this.locale);
        break;
      }
      case "de-DE": {
        this.dateAdapter.setLocale("de");
        registerLocaleData(localeGerman, this.locale);
        break;
      }
      case "sv-SE": {
        this.dateAdapter.setLocale("sv");
        registerLocaleData(localeSwedish, this.locale);
        break;
      }
      case "es-ES": {
        this.dateAdapter.setLocale("es");
        registerLocaleData(localeSpain, this.locale);
        break;
      }
      case "en-GB": {
        this.dateAdapter.setLocale("en-GB");
        registerLocaleData(localeEnglish, this.locale);
        break;
      }
      case "no-NO": {
        this.dateAdapter.setLocale("nb");
        registerLocaleData(localeNorwegian, this.locale);
        break;
      }
      case "da-DK": {
        this.dateAdapter.setLocale("da");
        registerLocaleData(localeDanish, this.locale);
        break;
      }
      case "fi-FI": {
        this.dateAdapter.setLocale("fi");
        registerLocaleData(localeFinnish, this.locale);
        break;
      }
      case "nl-NL": {
        this.dateAdapter.setLocale("nl");
        registerLocaleData(localeNetherlandish, this.locale);
        break;
      }
      case "nl-BE": {
        this.dateAdapter.setLocale("nl-BE");
        registerLocaleData(localeBelgiumNetherlandish, this.locale);
        break;
      }
      case "fr-BE": {
        this.dateAdapter.setLocale("fr-BE");
        registerLocaleData(localeBelgiumFrench, this.locale);
        break;
      }
      case "fr-FR": {
        this.dateAdapter.setLocale("fr");
        registerLocaleData(localeFrench, this.locale);
        break;
      }
      case "el-GR": {
        this.dateAdapter.setLocale("el");
        registerLocaleData(localeGreece, this.locale);
        break;
      }
      case "en-IE": {
        this.dateAdapter.setLocale("en-IE");
        registerLocaleData(localeIrelandEnglish, this.locale);
        break;
      }
      case "it-IT": {
        this.dateAdapter.setLocale("it");
        registerLocaleData(localeItalian, this.locale);
        break;
      }
      case "hu-HU": {
        this.dateAdapter.setLocale("hu");
        registerLocaleData(localeHungarian, this.locale);
        break;
      }
      case "cs-CZ": {
        this.dateAdapter.setLocale("cs");
        registerLocaleData(localeCzech, this.locale);
        break;
      }
      case "ru-RU": {
        this.dateAdapter.setLocale("ru");
        registerLocaleData(localeRussian, this.locale);
        break;
      }
      case "pl-PL": {
        this.dateAdapter.setLocale("pl");
        registerLocaleData(localePolish, this.locale);
        break;
      }
      case "en-US": {
        this.dateAdapter.setLocale("en-US-POSIX");
        registerLocaleData(localeUSEnglish, this.locale);
        break;
      }
      default: {
        registerLocaleData(localeEnglish, "en-GB");
        this.dateAdapter.setLocale("en-GB");
        this.localeStore$$.next("en-GB");
        return;
      }
    }
    this.localeStore$$.next(this.locale);
  }
}
