import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserInfo } from "angular-oauth2-oidc/types";
import { ReplaySubject } from "rxjs";
import { UserRequestModel } from "../../application/components/user-access/models";
import { AuthService } from "./auth.service";
import { User } from "../models/models.auth";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private readonly authService: AuthService,
    private readonly http: HttpClient
  ) {
    this.authService.userInfo$().subscribe((userInfo) => {
      this.init(userInfo);
    });
  }

  private readonly languageCode$$: ReplaySubject<string> =
    new ReplaySubject<string>(1);
  private readonly user$$: ReplaySubject<User> = new ReplaySubject<User>(1);

  public user: User;
  public languageCode: string;

  public User$() {
    return this.user$$.asObservable();
  }

  public init(userInfo: UserInfo) {
    this.user = User.fromUserInfo(userInfo);
    this.setLastActive();
    this.user$$.next(this.user);
  }

  public setLanguage(languageCode: string) {
    this.languageCode = languageCode;
    this.languageCode$$.next(this.languageCode);
  }

  public languageCode$() {
    return this.languageCode$$.asObservable();
  }

  public setLastActive() {
    this.sendLastActiveRequest();
  }

  private sendLastActiveRequest() {
    const market = localStorage.getItem("loadMarket");
    if (!market)
      return;

    const url = `/api/users/${encodeURIComponent(market)}/lastactive`;
    this.http.get(url).subscribe({
      next: () => {},
      error: (error) => {
        console.log("LOGG: LastActive: ", error);
      }
    });
  }

  private readonly result$$: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  public result$ = this.result$$.asObservable();

  private readonly userAccess$$: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  public userAccess$ = this.userAccess$$.asObservable();

  public saveLanguage(market: string, language: string) {
    const url = this.buildUrl(market);
    this.http.post(url, { language }).subscribe({
      next: () => {
        this.setLanguage(language);
        this.result$$.next(true);
      },
      error: () => this.result$$.next(false),
    });
  }

  public requestAccess(userRequest: UserRequestModel) {
    const userRequestBody = {
      name: this.user?.userName,
      message: userRequest.message,
    };
    const url = `/api/users/${userRequest.market}/requestAccess`;
    this.http.post(url, userRequestBody).subscribe({
      next: () => {
        this.userAccess$$.next(true);
      },
      error: () => this.userAccess$$.next(false),
    });
  }

  public async authenticate(): Promise<void> {
    return this.authService.authenticate();
  }

  private buildUrl(market: string): string {
    return `/api/users/${market}/settings`;
  }
}
