import { Injectable, OnDestroy } from "@angular/core";
import { merge } from "rxjs";
import { DistributionPointsService } from "../../../core/api/partner/distributionpoint/distribution-points.service";
import { DistributionPointsMapperService } from "../mapper/distribution-points-mapper.service";
import { ApplicationActions } from "../models/redux/actions/application-actions";
import { ApplicationActionsService } from "../redux/actions/application-actions.service";
import { ApplicationStateService } from "../redux/state/application-state.service";

@Injectable({
  providedIn: "root",
})
export class DistributionPointFacadeService implements OnDestroy {
  constructor(
    private readonly state: ApplicationStateService,
    private readonly action: ApplicationActionsService,
    private readonly distributionPointsService: DistributionPointsService,
    private readonly distributionPointsMapperService: DistributionPointsMapperService
  ) {}

  // COMMAND
  public setDistributionPoint(
    newDistributionPoint: string,
    oldDistributionPoint: string
  ) {
    this.action.dispatch(ApplicationActions.UPDATE_DISTRIBUTION_POINT, {
      newValue: newDistributionPoint,
      oldValue: oldDistributionPoint,
    });
  }

  public resetDistributionPointStatus() {
    this.action.dispatch(ApplicationActions.UPDATE_DISTRIBUTION_POINT_IDLE);
  }

  public loadDistributionPoints() {
    this.distributionPointsService.read();
  }

  private readonly triggerDistributionPointsData$ =
    this.distributionPointsService.data$((response) => {
      const distributionPoints =
        this.distributionPointsMapperService.mapAndSort(
          response.distributionPoints
        );
      this.state.update("distributionPoints", distributionPoints);
    });

  private readonly triggerDistributionPointsStatus$ =
    this.distributionPointsService.status$((value) =>
      this.state.update("distributionPointsStatus", value)
    );

  public stateDistributionPoints$() {
    return this.state.get$("distributionPoints");
  }

  public stateDistributionPointsStatus$() {
    return this.state.get$("distributionPointsStatus");
  }

  private readonly subscription = merge(
    this.triggerDistributionPointsData$,
    this.triggerDistributionPointsStatus$
  ).subscribe();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
