import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { merge, noop, of, ReplaySubject, Subject } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { SimpleServiceConfig } from "../../models/models.api.generic";

export enum SimpleServiceStatus {
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCEEDED = "SUCCEEDED",
  IDLE = "IDLE",
}

export interface APISimpleServiceState<T> {
  data: T;
  state: SimpleServiceStatus;
}

/**
 * @DEPRECATED use instead:
 * UpdateAPIService
 * DeleteAPIService - not implemented yet
 * ReadAPIService - not implemented yet
 */
@Injectable()
export class APISimpleService<T> implements OnDestroy {
  initialState = {
    data: null,
    state: SimpleServiceStatus.IDLE,
  };

  constructor(
    protected readonly http: HttpClient,
    protected readonly simpleServiceConfig: SimpleServiceConfig<T>
  ) {}

  public read() {
    this.readData$.next();
  }

  public delete(data: T) {
    this.deleteData$.next(data);
  }

  public update(data: T) {
    this.updateData$.next(data);
  }
  // == SOURCE OBSERVABLES

  // === STATE OBSERVABLES
  private readonly store$ = new ReplaySubject<
    Partial<APISimpleServiceState<T>>
  >(1);
  public readonly state$ = this.store$.asObservable().pipe(
    scan((acc: APISimpleServiceState<T>, newVal: APISimpleServiceState<T>) => {
      return { ...acc, ...newVal };
    }),
    startWith(this.initialState),
    shareReplay(1)
  );

  // == INTERACTION OBSERVABLESS
  private readonly readData$ = new Subject<void>();
  private readonly deleteData$ = new Subject<T>();
  private readonly updateData$ = new Subject<T>();

  // == INTERMEDIATE OBSERVABLES
  private readonly readTrigger$ = this.readData$.pipe(
    withLatestFrom(this.simpleServiceConfig.readUrl$()),
    mergeMap(([, readUrl]) =>
      this.http.get<T>(readUrl).pipe(
        catchError((err) => {
          return of(new Error(err));
        })
      )
    ),
    shareReplay()
  );

  private readonly deleteTrigger$ = this.deleteData$.pipe(
    switchMap((value) =>
      this.simpleServiceConfig.deleteUrl$(value).pipe(
        map((url) => {
          return [value, url] as [T, string];
        })
      )
    ),
    mergeMap(([value, deleteUrl]) =>
      this.http.delete(deleteUrl).pipe(
        map(() => value),
        catchError((err) => {
          console.error(err);
          return of(new Error(err));
        })
      )
    ),
    shareReplay()
  );

  private readonly updateTrigger$ = this.updateData$.pipe(
    switchMap((value) =>
      this.simpleServiceConfig.updateUrl$(value).pipe(
        map((url) => {
          return [value, url] as [T, string];
        })
      )
    ),
    mergeMap(([value, updateUrl]) =>
      this.http.put(updateUrl, this.simpleServiceConfig.updateRequestBody(value)).pipe(
        map(() => value),
        catchError((err) => {
          console.error(err);
          return of(new Error(err));
        })
      )
    ),
    shareReplay()
  );

  // == SIDEFFECT OBSERVABLES
  private readonly readLoadingTrigger$ = this.readData$.pipe(
    tap(() =>
      this.store$.next({
        state: SimpleServiceStatus.LOADING,
      })
    )
  );

  private readonly readResponseTrigger$ = this.readTrigger$.pipe(
    tap((value) => {
      if (value instanceof Error) {
        this.store$.next({
          state: SimpleServiceStatus.ERROR,
        });
      } else {
        this.store$.next({
          state: SimpleServiceStatus.SUCCEEDED,
          data: value
        });
      }
    })
  );

  private readonly deleteLoadingTrigger$ = this.deleteData$.pipe(
    tap(() => this.store$.next({ state: SimpleServiceStatus.LOADING }))
  );

  private readonly deleteResponseTrigger$ = this.deleteTrigger$.pipe(
    tap((value) => {
      if (value instanceof Error) {
        this.store$.next({ state: SimpleServiceStatus.ERROR });
      } else {
        this.store$.next({ state: SimpleServiceStatus.SUCCEEDED, data: value });
      }
    })
  );

  private readonly updateLoadingTrigger$ = this.updateData$.pipe(
    tap(() => this.store$.next({ state: SimpleServiceStatus.LOADING }))
  );

  private readonly updateResponseTrigger$ = this.updateTrigger$.pipe(
    tap((value) => {
      if (value instanceof Error) {
        this.store$.next({ state: SimpleServiceStatus.ERROR });
      } else {
        this.store$.next({  state: SimpleServiceStatus.SUCCEEDED, data: value });
      }
    })
  );

  // == OUTPUT OBSERVABLES
  public data$(predicate: (value: T) => void = noop) {
    return this.state$.pipe(
      filter((vm) => !!vm.data),
      map((vm) => vm.data),
      distinctUntilChanged(),
      tap((value) => (value ? predicate(value) : noop))
    );
  }

  public succeeded$(predicate: () => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state === SimpleServiceStatus.SUCCEEDED),
      distinctUntilChanged(),
      tap((succeeded) => (succeeded ? predicate() : noop)),
      filter(succeeded => succeeded)
    );
  }

  public error$(predicate: () => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state === SimpleServiceStatus.ERROR),
      distinctUntilChanged(),
      tap((error) => (error ? predicate() : noop)),
      filter(error => error)
    );
  }

  public loading$(predicate: () => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state === SimpleServiceStatus.LOADING),
      distinctUntilChanged(),
      tap((loading) => (loading ? predicate() : noop)),
      filter(loading => loading)
    );
  }

  public status$(predicate: (status: SimpleServiceStatus) => void = noop) {
    return this.state$.pipe(
      map((vm) => vm.state),
      distinctUntilChanged(),
      tap((simpleServiceStatus) => predicate(simpleServiceStatus))
    );
  }

  // == SUBSCRIPTIONS ==
  private readonly subscriptions = merge(
    this.readLoadingTrigger$,
    this.readResponseTrigger$,
    this.deleteLoadingTrigger$,
    this.deleteResponseTrigger$,
    this.updateLoadingTrigger$,
    this.updateResponseTrigger$
  ).subscribe();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
