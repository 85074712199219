import { BREAKPOINT } from "@angular/flex-layout";

const CUSTOM_BREAKPOINTS = [
  {
    alias: "mobile",
    mediaQuery: "(max-width: 600px)",
    overlapping: false,
  },
  {
    alias: "desktop",
    mediaQuery: "(min-width: 1024px) and (max-width: 1440px)",
    overlapping: false,
  },
  {
    alias: "tablet",
    mediaQuery: "(min-width: 601px) and (max-width: 1023px)",
    overlapping: false,
  },

  {
    alias: "lt-desktop",
    mediaQuery: "(max-width: 1023px)",
    overlapping: false,
  },

  {
    alias: "gt-mobile",
    mediaQuery: "(min-width: 601px)",
    overlapping: false,
  },

  {
    alias: "gt-desktop",
    mediaQuery: "(min-width: 1441px)",
    overlapping: false,
  },
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true,
};
