import { Injectable } from "@angular/core";
import { Actions } from "angular-simple-redux-lib";
import { ApplicationActions } from "../../models/redux/actions/application-actions";

@Injectable({
  providedIn: "any",
})
export class ApplicationActionsService extends Actions<ApplicationActions> {
  constructor() {
    super();
  }
}
