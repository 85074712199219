import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private readonly error$$ = new BehaviorSubject<boolean>(false);
  public error$ = this.error$$.asObservable();

  constructor() { }

  public updateError(value: boolean): void {
    this.error$$.next(value);
  }
}
