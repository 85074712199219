import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { Message } from "../../../../core/models/signalR/message";
import { BrowserHelperService } from "../../../../core/utils/browser-helper.service";
import { ApplicationState, MarketInfoDialogStatus } from "../../models/models";

import {
  ApplicationStateConfigService,
  ApplicationStateService,
} from "./application-state.service";

@Injectable()
export class ApplicationStateTestService extends ApplicationStateService {
  constructor(
    private readonly config: ApplicationStateConfigService,
    private readonly browserHelper: BrowserHelperService
  ) {
    super(config);
    console.log("LOGG: [Test] ApplicationStateTestService - constructor");
  }

  public get$<K extends keyof ApplicationState>(key: K) {
    if (this.browserHelper.parameter("show") === "displaynoticedialog") {
      if (key === "marketInfoDialogStatus") {
        return of(MarketInfoDialogStatus.open).pipe(delay(0)) as Observable<
          ApplicationState[K]
        >;
      }

      if (key === "noticeMessages") {
        return of([
          {
            language: "fi",
            message:
              "This market has some notice which is a very very very very very veryvery very very very veryvery very very very very very very very very long text",
          } as Message,
        ]) as Observable<ApplicationState[K]>;
      }

      if (key === "isNoticeAvailable") {
        return of(true) as Observable<ApplicationState[K]>;
      }
    }

    if (this.browserHelper.parameter("show") === "displaynotice") {
      if (key === "noticeMessages") {
        return of([
          {
            language: "fi",
            message:
              "This market has some notice which is a very very very very very veryvery very very very veryvery very very very very very very very very long text",
          } as Message,
        ]) as Observable<ApplicationState[K]>;
      }

      if (key === "isNoticeAvailable") {
        return of(true) as Observable<ApplicationState[K]>;
      }
    }

    return super.get$(key);
  }
}
