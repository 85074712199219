import { Injectable } from "@angular/core";
import { CanLoad, Router } from "@angular/router";
import { ContextService } from "../../application/shared/facade/context.service";

@Injectable({
  providedIn: "root",
})
export class NscAuthGuardService implements CanLoad {
  constructor(
    private readonly router: Router,
    private readonly contextService: ContextService,

  ) {}

  canLoad() {
    return (
      this.contextService.userContext.hasNscAdminRole ||
      this.contextService.userContext.hasNscRole ||
      this.router.navigate([""])
    );
  }
}
