import { Injectable } from "@angular/core";
import { EffectOrReducer } from "angular-simple-redux-lib";
import { tap, withLatestFrom } from "rxjs";
import { Partner } from "../../../../core/models/models.api";
import { ApplicationActions } from "../../models/redux/actions/application-actions";
import { ChangeDistributionPointError } from "../../models/redux/actions/change-distribution-point-payload";
import { ApiStatus } from "../../models/redux/store/api-status";
import { ApplicationActionsService } from "../actions/application-actions.service";
import { ApplicationStateService } from "../state/application-state.service";
import { mapToDistributionPoints } from "./functions/map-to-distribution-points.function";
import { mapToPartner } from "./functions/map-to-partner.function";

@Injectable({
  providedIn: "root",
})
export class UpdateDistributionPointErrorReducerService extends EffectOrReducer {
  constructor(
    private readonly state: ApplicationStateService,
    private readonly actionService: ApplicationActionsService
  ) {
    super();
    this.init();
  }

  protected actionObservable$() {
    return this.actionService
      .on<ChangeDistributionPointError>(
        ApplicationActions.UPDATE_DISTRIBUTION_POINT_FAILED
      )
      .pipe(
        withLatestFrom(
          this.state.get$("partner"),
          this.state.get$("distributionPoints")
        ),
        tap(([changeDistributionPointError, partner, distributionPoints]) => {
          const newDistributionPoints = mapToDistributionPoints(
            distributionPoints,
            changeDistributionPointError.changeDistributionPointPayload.oldValue
          );
          const newPartner: Partner = mapToPartner(
            newDistributionPoints,
            changeDistributionPointError.changeDistributionPointPayload
              .oldValue,
            partner
          );

          this.state.update("distributionPoints", newDistributionPoints);
          this.state.update("partner", newPartner);
          this.state.update("distributionPointStatus", ApiStatus.ERROR);
          this.state.update(
            "distributionPointError",
            changeDistributionPointError.httpErrorResponse
          );
        })
      );
  }
}
