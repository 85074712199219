import { HttpErrorResponse } from "@angular/common/http";
import { SimpleServiceStatus } from "../../../core/api/generic/api-simple.service";
import {
  DistributionPoint,
  Partner,
  UserMarket,
} from "../../../core/models/models.api";
import { ApiServiceStatus } from "../../../core/models/models.api.generic";
import { Message } from "../../../core/models/signalR/message";
import { ListItem } from "../../../ui/form-controls/vcc-select/models";
import { ApiStatus } from "./redux/store/api-status";

export enum SavingStatus {
  Idle,
  Saving,
  Succeeded,
  Failed,
}

export enum RoleType {
  Retailer = "retailer",
  Nsc = "nsc",
  NscAdmin = "nscadmin",
}

export enum MarketInfoDialogStatus {
  "open",
  "closed",
}
export class UserContext {
  constructor(private readonly _role: string[] = []) {}

  public get hasNscAdminRole() {
    return !!this._role.find(
      (role) => role.toLowerCase() === RoleType.NscAdmin
    );
  }
  public get hasNscRole() {
    return !!this._role.find((role) => role.toLowerCase() === RoleType.Nsc);
  }
  public get hasRetailerRole() {
    return !!this._role.find(
      (role) => role.toLowerCase() === RoleType.Retailer
    );
  }

  public get roles() {
    return this._role.join(",");
  }
}

export interface ApplicationState {
  partner: Partner | null;
  partnerStatus: SimpleServiceStatus;
  distributionPoints: DistributionPoint[] | null;
  distributionPointsStatus: SimpleServiceStatus;
  distributionPointStatus: ApiStatus;
  distributionPointError: HttpErrorResponse;
  leadtimeShutdownTexts: Message[];
  marketInfoDialogStatus: MarketInfoDialogStatus;
  isShutdownEnabled: boolean;
  shutdownMessages: Message[];
  isNoticeAvailable: boolean;
  noticeMessages: Message[];
  updateShutdownStatus: ApiServiceStatus;
  updateNoticeStatus: ApiServiceStatus;
}

export interface NavbarLinks {
  title: string;
  show: boolean;
}

export interface NavbarCategories {
  show: boolean;
  header: string;
  navLinks: NavbarLinks[];
}

export interface NavbarViewModel {
  partner: Partner;
  partnerLoading: boolean;
  partnerError: boolean;
  isMobile: boolean;
  searchDialogIsOpen: boolean;
  settingsDialogIsOpen: boolean;
  currentUserName: string;
  currentCountryCode: string;
  selectedLanguageCode: string;
  leadtimesIsShutdown: boolean;
  navigationMenus: NavbarCategories[];
  isAdmin: boolean;
}

export interface UserSettingsViewModel {
  distributionPoints: ListItem[];
  selectedDistributionPoint: string;
  markets: ListItem[];
  selectedMarket: UserMarket;
  languages: ListItem[];
  selectedLanguage: string;
  distributionPointError: string;
}

export interface LanguageMessage {
  languageCode: string;
  language: string;
  text: string;
}
