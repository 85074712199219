import { Injectable, OnDestroy } from "@angular/core";
import {
  combineLatest,
  lastValueFrom,
  map,
  merge,
  Observable,
  take,
  tap,
  withLatestFrom,
} from "rxjs";
import { SubSink } from "subsink";
import { UpdateNoticeService } from "../../../core/api/notice/update-notice.service";
import { UpdateShutdownService } from "../../../core/api/shutdown/update-shutdown.service";
import { LeadtimeShutdownClientService } from "../../../core/api/signal-r-clients/leadtime-shutdown-client.service";
import { NoticeClientService } from "../../../core/api/signal-r-clients/notice-client.service";
import { SignalRService } from "../../../core/api/signal-r/signal-r.service";
import { Events, LogService } from "../../../core/logging/log.service";
import { Notification } from "../../../core/models/signalR/notification";
import { LanguageMessage, MarketInfoDialogStatus } from "../models/models";
import { ApplicationStateService } from "../redux/state/application-state.service";

import { ContextService } from "./context.service";

@Injectable({
  providedIn: "root",
})
export class MarketStatusFacadeService implements OnDestroy {
  private readonly subSink = new SubSink();

  constructor(
    private readonly state: ApplicationStateService,
    private readonly contextService: ContextService,
    private readonly signalRService: SignalRService,
    private readonly shutdownClient: LeadtimeShutdownClientService,
    private readonly noticeClient: NoticeClientService,
    private readonly updateShutdownService: UpdateShutdownService,
    private readonly noticeService: UpdateNoticeService,
    private readonly logService: LogService
  ) {}

  public async initSignalR() {
    const market = await lastValueFrom(
      this.state.getCurrentMarket$().pipe(take(1))
    );
    await this.signalRService.startConnection(market.programMarket);
  }

  /**
   * STATE: SHUTDOWN
   */

  // ACTIONS
  public saveShutdownNotification(shutdownNotification: Notification) {
    this.updateShutdownService.execute(shutdownNotification);
  }

  // REDUCERS
  private readonly reducerIsShutdownAvaliable$ = this.shutdownClient
    .isEnabled$()
    .pipe(tap((value) => this.state.update("isShutdownEnabled", value)));

  private readonly reducerShutdownMessages$ = this.shutdownClient
    .shutDownTexts$()
    .pipe(tap((value) => this.state.update("shutdownMessages", value)));

  private readonly reducerUpdateShutdownStatus$ = this.updateShutdownService
    .status$()
    .pipe(tap((value) => this.state.update("updateShutdownStatus", value)));

  private readonly reducerUpdateShutdownMessages$ = this.updateShutdownService
    .on_success$()
    .pipe(
      withLatestFrom(this.updateShutdownService.requestContext$()),
      tap(([_, shutdown]) => {
        this.state.update("shutdownMessages", shutdown.messages);
        this.state.update("isShutdownEnabled", shutdown.isEnabled);

        if (shutdown.isEnabled) {
          this.logService.logEvent(Events.SET_SHUTDOWNMARKET, {
            Succeeded: true,
            ShutdownMarket: shutdown,
          });
        } else {
          this.logService.logEvent(Events.REMOVE_SHUTDOWNMARKET, {
            Succeeded: true,
            ShutdownMarket: shutdown,
          });
        }
      })
    );

  private readonly reducerUpdateShutdownMessagesFailed$ =
    this.updateShutdownService.on_failure$().pipe(
      withLatestFrom(this.updateShutdownService.requestContext$()),
      tap(([_, shutdown]) => {
        if (shutdown.isEnabled) {
          this.logService.logEvent(Events.SET_SHUTDOWNMARKET, {
            Succeeded: false,
            ShutdownMarket: shutdown,
          });
        } else {
          this.logService.logEvent(Events.REMOVE_SHUTDOWNMARKET, {
            Succeeded: false,
            ShutdownMarket: shutdown,
          });
        }
      })
    );

  // SELECTORS
  public shutdownEnabled$(): Observable<boolean> {
    return this.state.get$("isShutdownEnabled");
  }

  public shutdownMessage$() {
    return combineLatest([
      this.state.get$("shutdownMessages"),
      this.contextService.currentLanguage$(),
    ]).pipe(
      map(([texts, language]) => {
        return (
          texts.find((value) => value.language === language)?.message ?? ""
        );
      })
    );
  }

  public shutdownMessages$(): Observable<LanguageMessage[]> {
    return combineLatest([
      this.state.get$("shutdownMessages"),
      this.contextService.marketLanguages$(),
    ]).pipe(map(this.mapToLanguageMessages));
  }

  public updateShutdownStatus$() {
    return this.state.get$("updateShutdownStatus");
  }

  /**
   * STATE: NOTICE
   */

  // ACTIONS
  public saveNoticeNotification(noticeNotification: Notification) {
    this.noticeService.execute(noticeNotification);
  }

  // REDUCERS
  private readonly reducerIsNoticeAvaliable$ = this.noticeClient
    .isEnabled$()
    .pipe(tap((value) => this.state.update("isNoticeAvailable", value)));

  private readonly reducerNoticeMessages$ = this.noticeClient
    .messages$()
    .pipe(tap((value) => this.state.update("noticeMessages", value)));

  private readonly reducerUpdateNoticeStatus$ = this.noticeService
    .status$()
    .pipe(
      tap((value) => {
        this.state.update("updateNoticeStatus", value);
      })
    );

  public reducerUpdateNoticeMessages$ = this.noticeService.on_success$().pipe(
    withLatestFrom(this.noticeService.requestContext$()),
    tap(([_, notification]) => {
      this.state.update("noticeMessages", notification.messages);
      this.state.update("isNoticeAvailable", notification.isEnabled);

      //LOG EVENT
      if (notification.isEnabled) {
        this.logService.logEvent(Events.SET_NOTICE, {
          Succeeded: true,
          NoticeForMarket: notification,
        });
      } else {
        this.logService.logEvent(Events.REMOVE_NOTICE, {
          Succeeded: true,
          NoticeForMarket: notification,
        });
      }
    })
  );

  private readonly reducerUpdateNoticeMessagesFailed$ = this.noticeService
    .on_failure$()
    .pipe(
      withLatestFrom(this.noticeService.requestContext$()),
      tap(([_, notice]) => {
        if (notice.isEnabled) {
          this.logService.logEvent(Events.SET_NOTICE, {
            Succeeded: false,
            NoticeForMarket: notice,
          });
        } else {
          this.logService.logEvent(Events.REMOVE_NOTICE, {
            Succeeded: false,
            NoticeForMarket: notice,
          });
        }
      })
    );

  // SELECTORS
  public noticeAvailable$() {
    return this.state.get$("isNoticeAvailable");
  }

  public updateNoticeStatus$() {
    return this.state.get$("updateNoticeStatus");
  }

  public noticeMessage$() {
    return combineLatest([
      this.state.get$("noticeMessages"),
      this.contextService.currentLanguage$(),
    ]).pipe(
      map(([messages, languageCode]) => {
        return (
          messages.find((message) => message.language === languageCode)
            ?.message ?? ""
        );
      })
    );
  }

  public noticeMessages$(): Observable<LanguageMessage[]> {
    return combineLatest([
      this.state.get$("noticeMessages"),
      this.contextService.marketLanguages$(),
    ]).pipe(map(this.mapToLanguageMessages));
  }

  /**
   * MISC
   *  */
  private mapToLanguageMessages([messages, languages]) {
    return languages.map((language) => {
      return {
        languageCode: language.code,
        language: language.description,
        text:
          messages.find((message) => message.language === language.code)
            ?.message ?? "",
      };
    });
  }

  /**
   * STATE: MARKET INFORMATION DIALOG
   */
  public marketInfoDialogStatus$() {
    return this.state.get$("marketInfoDialogStatus");
  }
  public openMarketInfoDialog() {
    this.state.update("marketInfoDialogStatus", MarketInfoDialogStatus.open);
  }
  public closeMarketInfoDialog() {
    this.state.update("marketInfoDialogStatus", MarketInfoDialogStatus.closed);
  }

  private readonly subscriptions = merge(
    this.reducerIsNoticeAvaliable$,
    this.reducerNoticeMessages$,
    this.reducerIsShutdownAvaliable$,
    this.reducerShutdownMessages$,
    this.reducerUpdateShutdownStatus$,
    this.reducerUpdateShutdownMessages$,
    this.reducerUpdateShutdownMessagesFailed$,
    this.reducerUpdateNoticeStatus$,
    this.reducerUpdateNoticeMessages$,
    this.reducerUpdateNoticeMessagesFailed$
  ).subscribe();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subSink.unsubscribe();
  }
}
