import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { APIBodyServiceConfig } from "../../models/models.api.generic";
import { Notification } from "../../../core/models/signalR/notification";



@Injectable({
  providedIn: 'root'
})
export class UpdateNoticeBodyConfigService extends APIBodyServiceConfig<Notification> {
  constructor() {
    super();
  }

  body$ = (item: Notification) => {
    return  of(item);
  };
}
