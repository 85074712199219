import { UpdateDistributionPointEffectService } from "../../../redux/effects/update-distribution-point-effect.service";
import { UpdateDistributionPointLogFailedEffectService } from "../../../redux/effects/update-distribution-point-log-failed-effect.service";
import { UpdateDistributionPointLogSucceededEffectService } from "../../../redux/effects/update-distribution-point-log-succeeded-effect.service";
import { UpdateDistributionPointErrorReducerService } from "../../../redux/reducers/update-distribution-point-error-reducer.service";
import { UpdateDistributionPointIdleReducerService } from "../../../redux/reducers/update-distribution-point-idle-reducer.service";
import { UpdateDistributionPointPendingReducerService } from "../../../redux/reducers/update-distribution-point-pending-reducer.service";
import { UpdateDistributionPointSucceededReducerService } from "../../../redux/reducers/update-distribution-point-succeeded-reducer.service";

export const ApplicationReducersAndEffects = [
  UpdateDistributionPointEffectService,
  UpdateDistributionPointLogSucceededEffectService,
  UpdateDistributionPointLogFailedEffectService,
  UpdateDistributionPointPendingReducerService,
  UpdateDistributionPointErrorReducerService,
  UpdateDistributionPointSucceededReducerService,
  UpdateDistributionPointIdleReducerService,
];
