import { Injectable } from "@angular/core";
import { ContextService } from "../../../application/shared/facade/context.service";
import { RoleType } from "../../../application/shared/models/models";
import { MappedUserList, User } from "../model/users.service.models";

@Injectable({
  providedIn: "root",
})
export class UsersMapperService {
  private readonly selectedMarket$ = this.contextService.currentCountry$();
  market: string;

  constructor(private readonly contextService: ContextService) {
    this.contextService.currentCountry$().subscribe((res) => {
      this.market = res;
    });
  }

  mapToState(list: User[]): MappedUserList[] {
    const mappedUser: MappedUserList[] = [];
    list.forEach((user) => {
      mappedUser.push({
        id: user.id,
        cdsid: user.cdsid,
        email: user.email,
        market: this.market,
        partner: user.partner,
        role:
          user.role.toLowerCase() === RoleType.NscAdmin
            ? "Nsc Admin"
            : user.role,
        lastActive: user.lastActive
      });
    });

    return mappedUser;
  }
}
