import { Injectable, Injector } from "@angular/core";
import { EffectsAndReducersLoader } from "angular-simple-redux-lib";
import { ApplicationReducersAndEffects } from "../../models/redux/loader/application.effects.and.reducers";

@Injectable({
  providedIn: "root",
})
export class ApplicationEffectsAndReducerLoaderService extends EffectsAndReducersLoader {
  protected services = ApplicationReducersAndEffects;
  constructor(private readonly injector: Injector) {
    super(injector);
  }
}
