import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslationService } from "../../../core/label/translation.service";

@Component({
  selector: "app-dialog-ie-warning",
  templateUrl: "./dialog-ie-warning.component.html",
  styleUrls: ["./dialog-ie-warning.component.scss"],
})
export class DialogIeWarningComponent implements OnInit {
  public browserWarningMessage: string;
  constructor(
    public readonly translationService: TranslationService,
    private readonly dialogRef: MatDialogRef<DialogIeWarningComponent>
  ) {}

  ngOnInit(): void {
    this.createDialogMessage();
  }

  createDialogMessage() {
    const windowsLink =
      "https://blogs.windows.com/windowsexperience/2021/05/19/the-future-of-internet-explorer-on-windows-10-is-in-microsoft-edge/";
    const message = this.translationService.get("browserWarningMessage");
    const anchor = `<a style="color: #0078d4;" target="_blank" href=${windowsLink}>${this.translationService.get(
      "browserWarningLinkTitle"
    )}</a>`;
    const editedMessage = message.replace("{0}", anchor);
    const body = document.getElementById("dialog-body");
    body.innerHTML = editedMessage;
  }

  close() {
    this.dialogRef.close();
  }
}
