import { Location } from "@angular/common";
import { Injectable, OnDestroy } from "@angular/core";
import { OAuthErrorEvent, OAuthService, UserInfo } from "angular-oauth2-oidc";
import {
  defer,
  lastValueFrom,
  merge,
  Observable,
  of,
  ReplaySubject,
  throwError,
} from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  map,
  takeWhile,
  tap,
} from "rxjs/operators";
import { SubSink } from "subsink";
import { environment } from "../../../environments/environment";
import { BootstrapError } from "../models/models.auth";
import { BrowserHelperService } from "../utils/browser-helper.service";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  private readonly userInfo$$ = new ReplaySubject<UserInfo>(1);
  private readonly subSink = new SubSink();

  private retryRefreshTokenCount = 0;
  private readonly retryRefreshTokenLimit = 50;

  private retryCount = 0;
  private readonly maxRetryCount = 3;

  constructor(
    private readonly oauthService: OAuthService,
    private readonly location: Location,
    private readonly browserHelper: BrowserHelperService
  ) {}

  // == INTERMEDIATE OBSERVABLES ================
  private readonly invalidGrantTrigger$ = this.oauthService.events.pipe(
    filter((value) => value instanceof OAuthErrorEvent),
    map((value) => value as OAuthErrorEvent),
    map((value) => {
      const reasonObject: any = { ...value.reason };
      return reasonObject?.error?.error ?? "";
    }),
    filter((value) => value === "invalid_grant" || value === "invalid_nonce_in_state")
  );

  private readonly tryLogin$ = defer(() => this.oauthService.tryLogin());

  private readonly setupLoggedInUser$ = defer(() =>
    this.setupLoggedInUser()
  ).pipe(catchError(() => this.login$));

  private readonly hasCorrelationError$ = new Observable((observer) => {
    if (this.hasCorrelationError()) {
      observer.error();
    } else {
      observer.next();
      observer.complete();
    }
  }).pipe(catchError((err) => throwError(() => 105)));

  private readonly login$ = new Observable(() => {
    //User not logged in, trigger login flow
    const loginHint = this.getLoginHint();
    const params = loginHint === null ? {} : { login_hint: loginHint };
    this.oauthService.initLoginFlow(
      this.browserHelper.getAdditionalLoginState(),
      params
    );
  });

  // == SIDEFFECTS ============================
  private readonly invalidGrantReload$ = this.invalidGrantTrigger$.pipe(
    tap(async () => {
      this.oauthService.initLoginFlow(window.location.search);
    })
  );

  private setupLoggedInUser(): Promise<UserInfo> {
    this.parseQueryStringInAuthenticationState();
    this.setupSilentRefreshAndRetryAndReload();
    return this.getUserInfo();
  }

  private parseQueryStringInAuthenticationState() {
    if (this.oauthService.state !== "") {
      const state = decodeURIComponent(this.oauthService.state);
      const additionalState =
        this.browserHelper.createAdditionalLoginState(state);

      this.location.replaceState(
        additionalState.path,
        additionalState.parameters
      );
    }
  }

  private setupSilentRefreshAndRetryAndReload() {
    this.oauthService.setupAutomaticSilentRefresh();

    this.subSink.sink = this.oauthService.events
      .pipe(
        filter((value) => value.type === "token_refresh_error"),
        tap(() => this.incrementRetryRefreshTokenCount()),
        takeWhile(
          (value) => this.retryRefreshTokenLimit >= this.retryRefreshTokenCount
        ),
        tap(() =>
          console.log(
            "Refresh token failed, retry in 10 sec, attempt",
            this.retryRefreshTokenCount
          )
        ),
        debounceTime(10000)
      )
      .subscribe(() => {
        this.oauthService.refreshToken();
      });

    this.subSink.sink = this.oauthService.events
      .pipe(filter((value) => value.type === "token_refreshed"))
      .subscribe((value) => {
        this.retryRefreshTokenCount = 0;
      });
  }

  private incrementRetryRefreshTokenCount() {
    this.retryRefreshTokenCount++;
  }

  private getUserInfo() {
    const currentUser = this.getCachedUserInfo();
    if (currentUser !== null) {
      return Promise.resolve(currentUser);
    }
    return this.oauthService
      .loadUserProfile()
      .then((value) => value["info"]) as Promise<UserInfo>;
  }

  private getCachedUserInfo(): UserInfo {
    const userString = sessionStorage.getItem("user");

    if (userString !== null) {
      const currentTimeStamp = Date.now() / 1000;
      const user = JSON.parse(userString);

      if (currentTimeStamp < user.exp) {
        return user;
      }
    }

    return null;
  }

  private hasCorrelationError() {
    const queryStringInUri = this.location.path();
    const queryStrings = queryStringInUri.split(/\?|&/);
    return queryStrings.find((qs) => qs === "error=correlationfailed");
  }

  private getLoginHint(): string {
    return this.browserHelper.parameter("login_hint");
  }

  public authenticate(): Promise<any> {
    this.setupAuthConfig();

    return lastValueFrom(
      this.hasCorrelationError$.pipe(
        concatMap(() => this.tryLogin$),
        concatMap(() => this.setupLoggedInUser$),
        tap((userInfo: UserInfo) => {
          this.userInfo$$.next(userInfo);
          this.cacheUserInfo(userInfo);
        }),
        catchError((error) => {

          if (error instanceof OAuthErrorEvent && error.type === "invalid_nonce_in_state") {
            // Handle the 'invalid_nonce_in_state' error
            console.log("Invalid nonce in state error occurred");
            // Perform hard reload if retry count is less than the maximum allowed retries
            if (this.retryCount < this.maxRetryCount) {
              this.retryCount++;
              console.log("Performing hard reload... Attempt", this.retryCount);
              // Perform hard reload after 3 seconds
              setTimeout(() => window.location.reload(), 3000);
              // Return null to indicate the error has been handled
              return of(null);
            }
          }

          // Handle other errors or re-throw the error

          const errorNumber = typeof error === "number" ? error : 100;
          return throwError(() => BootstrapError.Create(errorNumber));
        })
      )
    );
  }

  private setupAuthConfig() {
    let redirectUri = environment.settings.authConfig.redirectUri;
    try {
      redirectUri = window.location.origin;
      environment.settings.authConfig.redirectUri = redirectUri;
    } catch (Error) {}

    const clientId = window.sessionStorage.getItem("clientId") ?? "lit";
    this.oauthService.configure({
      ...environment.settings.authConfig,
      clientId,
    });
  }

  private cacheUserInfo(user: UserInfo) {
    sessionStorage.setItem("user", JSON.stringify(user));
  }

  // == OUTPUT ==========================
  public userInfo$() {
    return this.userInfo$$.asObservable();
  }

  // == SUBSCRIPTIONS ===================
  private readonly subscriptions = merge(this.invalidGrantReload$).subscribe();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subSink.unsubscribe();
  }
}
