import { ListItem } from "../../../ui/form-controls/vcc-select/models";

export interface UsersResponse {
  responseDetails: User[];
}

export interface User {
  id: number;
  cdsid: string;
  email: string;
  role: string;
  market: number;
  partner: string;
  lastActive: string;
}

export enum RoleName {
  Retailer = "Retailer",
  Nsc = "Nsc",
  NscAdmin = "Nsc Admin",
}

export interface MappedUserList {
  id: number;
  cdsid: string;
  email: string;
  role: string;
  market: string;
  partner: string;
  lastActive: string;
}

export interface Roles {
  roles: ListItem[];
}

export enum DialogStatus {
  "open",
  "closed",
}

export interface UsersState {
  error: boolean;
  loading: boolean;
  users: User[];
  mappedUser: MappedUserList[];
  addUsersDialogStatus: DialogStatus;
  editUsersDialogStatus: DialogStatus;
  editingUser: MappedUserList;
  deleteUserDialogStatus: DialogStatus;
}
