import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Features } from "../../models/models.api";
import { APISingleService } from "./../generic/api-single.service";

@Injectable({
  providedIn: "root",
})
export class FeaturetoggleService extends APISingleService<Features[]> {
  constructor(private readonly httpService: HttpClient) {
    super(httpService, "/api/featuretoggler");
  }

  public isActive(featureName: string): boolean {
    return !!this.getValue()?.find(x => x.featureName === featureName);
  }
}
