import { Injectable, OnDestroy } from "@angular/core";
import { combineLatest, interval } from "rxjs";
import { take, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { FiltersService } from "../../../core/api/filter/filters.service";
import { UserService } from "../../../core/auth/user.service";
import { ApplicationEffectsAndReducerLoaderService } from "../redux/loader/application-effects-and-reducer-loader.service";
import { ApplicationStateService } from "../redux/state/application-state.service";
import { BrowserFacadeService } from "./browser-facade.service";
import { DistributionPointFacadeService } from "./distribution-point-facade.service";
import { MarketFacadeService } from "./market-facade.service";
import { PartnerFacadeService } from "./partner-facade.service";
import { UserFacadeService } from "./user-facade.service";

@Injectable()
export class ApplicationFacadeService implements OnDestroy {
  private readonly subSink = new SubSink();

  constructor(
    private readonly state: ApplicationStateService,
    private readonly userService: UserService,
    private readonly filterService: FiltersService,
    public readonly browser: BrowserFacadeService,
    public readonly market: MarketFacadeService,
    public readonly distributionPoint: DistributionPointFacadeService,
    public readonly user: UserFacadeService,
    public readonly partner: PartnerFacadeService,
    private readonly applicationEffectsAndReducerLoaderService: ApplicationEffectsAndReducerLoaderService
  ) {
    this.applicationEffectsAndReducerLoaderService.load();
  }

  public setupUpdateLeadtimeFilters(filterRefreshRate: number) {
    this.subSink.sink = interval(filterRefreshRate * 1000)
      .pipe(tap(() => this.updateLeadtimeFilters()))
      .subscribe();
  }

  public updateLeadtimeFilters() {
    combineLatest([
      this.state.getCurrentMarket$(),
      this.userService.languageCode$(),
    ])
      .pipe(
        tap(([currentMarket, selectedLanguage]) => {
          this.filterService.updateFilters(
            currentMarket.programMarket,
            selectedLanguage
          );
        }),
        take(1)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
