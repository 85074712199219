<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
  <div>
    <mat-icon class="vcc-svgIcon icon-white" [svgIcon]="iconName"></mat-icon>
  </div>
  <div>
    {{ data?.message || "Undefined" }}
  </div>
  <button class="mat-simple-snackbar-action vcc-pointer" (click)="close()">
    {{ data?.dismiss || "Undefined" }}
  </button>
</div>
